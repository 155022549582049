import React, { useState, Fragment, useEffect } from "react";
import { FileState, IAttachment, IFileUpload } from "../../app/models/attachment";
import { Row, Col, Button, ListGroup } from "react-bootstrap";
import FileUploadingListItem from "./FileUploadingListItem";

interface IProps {
  files: IFileUpload[];
  deleteFileToUpload: (uid: string) => void;
  setupUploadingFileState: (file: IFileUpload) => void;
}

const FilesUploadingList: React.FC<IProps> = ({
  files,
  deleteFileToUpload,
  setupUploadingFileState
}) => {

  const [ startUploding, setStartUploding] = useState<boolean>(false);
  const [ currentUid, setCurrentUid ] = useState<string | undefined>(undefined);

  const handleUploadFiles = () => {
    let arr: string[] = files.filter(x => x.state === FileState.Selected).map(x => {return x.uid});
    if(arr.length > 0) {
      setStartUploding(true);
      setCurrentUid(arr.shift());
    }
    else{
      setStartUploding(false);
      setCurrentUid(undefined);
    }
  };

  useEffect(() => {
    if(startUploding) handleUploadFiles();
  }, [files, startUploding, currentUid]);

  return (
    <Fragment>
      <Row>
        <Col className="selected-files">
          <h3>Vybrané soubory k uložení</h3>
          <ListGroup as="ul">
            {files.filter(x => x.state !== FileState.Done).map((file) => {
              return (
                <FileUploadingListItem
                  key={file.uid}
                  file={file}
                  startUploading={startUploding && file.state === FileState.Selected && file.uid === currentUid}
                  deleteFileToUpload={deleteFileToUpload}
                  setupUploadingFileState={setupUploadingFileState}
                />
              );
            })}
          </ListGroup>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-end pt-2">
          <Button
            variant="primary"
            type="button"
            onClick={() => {
              handleUploadFiles();
            }}
          >
            Uložit
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
};

export default FilesUploadingList;
