import "./CarBodyProtocol.scss";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {Field, Form as FinalForm} from "react-final-form";
import {Link, RouteComponentProps} from "react-router-dom";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {observer} from "mobx-react-lite";
import {ICarBodyProtocol, IDefect, IProtocolArea, IProtocolPartRule} from "../../../app/models/protocol";
import {Button, Col, Collapse, Form, Row, Table} from "react-bootstrap";
import {CheckAllItemsEmpty, validatePartRules} from "./protocolFunctions";
import ProtocolArea from "./protocolParts/ProtocolArea";
import EditSaveResetButtons from "../../ButtonGroups/EditSaveResetButtons";
import logo from "../../../assets/images/Skoda_Wordmark_RGB_Emerald_Green.svg";
import ProtocolRule from "./protocolParts/ProtocolRule";
import ProtocolGroup from "./protocolParts/ProtocolGroup";
import DefectForm from "./protocolParts/DefectForm";
import CarBodyDefectsArea from "./protocolParts/CarBodyDefectsArea";
import ProtocolHeaderCarInfo from "./protocolParts/ProtocolHeaderCarInfo";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import Can from "../../../authorization/Can";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint, faFileExcel, faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import TextInput from "../../../app/common/form/TextInput";
import SimpleTextInput from "../../../app/common/form/SimpleTextInput";
import moment from "moment";
import AlertMessage from "../../alert/AlertMessage";
import agent from "../../../app/api/agent";
import { IAttachment } from "../../../app/models/attachment";
import AttachmentsList from "../../attachments/AttachmentsList";
import MultiFilesUploaderPanel from "../../filesUpload/MultiFilesUploaderPanel";

interface PartialProtocolParams {
  protocolTypeId: string;
  carId: string;
  carTestId: string;
  carProtocolId: string;
}

const CarBodyProtocol: React.FC<RouteComponentProps<PartialProtocolParams>> = ({
  match,
  history,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { loadEnums, enums } = rootStore.enumStore;
  const { user } = rootStore.authStore;
  const {
    loadCarBodyProtocol,
    carBodyProtocolRegistry,
    isLoading,
    saveCarBodyProtocol,
  } = rootStore.protocolStore;
  const [carBodyProtocol, setCarBodyProtocol] = useState<
    ICarBodyProtocol | undefined
  >(undefined);
  const [defects, setDefects] = useState<IDefect[]>([]);
  const [edit, setEdit] = useState(false);

  const [errors, setErrors] = useState([]);
  const [showSubmitError, setShowSubmitError] = useState(false);

  const { openModal } = rootStore.modalStore;
  let printedArea = -1;
  let printedGroup = -1;

  const protocolTypeId = Number(match.params.protocolTypeId);
  const carId = Number(match.params.carId);
  const carTestId = Number(match.params.carTestId);
  const carProtocolId = Number(match.params.carProtocolId);
  const existsCarProtocolId = match.params.carProtocolId && match.params.carProtocolId !== "null";

  const [attachments, setAttachments ] = useState<IAttachment[] | undefined>(undefined);
  const [attachmentsCollapsed, setAttachmentsCollapsed] = useState(false);

  useEffect(() => {

    loadCarBodyProtocol(carId, carTestId, protocolTypeId, carProtocolId).then(
      () => {
        const loadedCarBodyProtocol = carBodyProtocolRegistry.get(
          `C${carId}-CT${carTestId}-PT${protocolTypeId}`
        );
        setCarBodyProtocol(loadedCarBodyProtocol);
        setDefects(
          loadedCarBodyProtocol?.defects?.map((d, index: number) => {
            d.rownumber = index;
            return d;
          }) || []
        );
        setAttachments(loadedCarBodyProtocol?.attachments);
        if(loadedCarBodyProtocol?.attachments && loadedCarBodyProtocol?.attachments.length > 0) setAttachmentsCollapsed(true);
      }
    );
    loadEnums();
  }, [
    match.params.carId,
    match.params.carTestId,
    match.params.protocolTypeId,
    loadCarBodyProtocol,
    loadEnums,
    carBodyProtocolRegistry,
  ]);

  const handleFinalFormSubmit = (values: ICarBodyProtocol) => {
    //values.defects = defects; // timhle se prepisi hodnoty, co vlozil uzivatel defaultnimi z radku 195, to asi nechceme
    carBodyProtocolRegistry.set(
      `C${values.carId}-CT${values.carTestId}-PT${values.protocolTypeId}`,
      values
    );

    setErrors([]);
    setShowSubmitError(false);

    saveCarBodyProtocol(values)
    .then((response) => {
      setEdit(false);
      if (!match.params.carProtocolId || match.params.carProtocolId == 'null')
        history.push(
          `/CarBodyProtocol/${response.protocolTypeId}/${response.carId}/${response.carTestId}/null/${response.carProtocolId}`
      );
      setCarBodyProtocol(response);
    })
    .catch((error) => {
        setErrors(Object.values(error.response?.data?.errors));
        setShowSubmitError(true);
    });

  };

  // Reset all form values to initial values
  const handleReset = (form: any) => {

    const loadedCarBodyProtocol = carBodyProtocolRegistry.get(
      `C${carId}-CT${carTestId}-PT${protocolTypeId}`
    );
    setCarBodyProtocol(loadedCarBodyProtocol);

    setDefects(
      loadedCarBodyProtocol?.defects?.map((d, index: number) => {
        d.rownumber = index;
        return d;
      }) || []
    );
    form.reset();
    setEdit(false);
  };

  const handleGetDocument = async () => {

    try {
      const protocolTypeId = Number(match.params.protocolTypeId);

      var response = await agent.Protocols.getProtocolDocumentXlsx(carId, carTestId, protocolTypeId, carProtocolId);
      const href = URL.createObjectURL(response.data);
      console.log(response.headers);

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'file.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
        console.error("Error downloading:", error);
    }
  };

  const handleOpenModelDefectForm = (edit: boolean, protocolRuleId: number, values: ICarBodyProtocol, partName: string | undefined) => {
    //var defect: IDefect | undefined = defects?.find(x => x.protocolRuleId === protocolRuleId);
    var defect: IDefect | undefined = values.defects?.find(
      (x) => x.protocolRuleId == protocolRuleId && x.isDeleted == false
    );
    if (defect == undefined) {
      var rowNumber: number = 0;
      if (defects && defects.length > 0) {
        const filteredArray = defects
          .filter((x) => x.rownumber !== undefined)
          .map((x) => (x.rownumber ? x.rownumber : 0));
        if (filteredArray && filteredArray.length > 0) {
          rowNumber = Math.max.apply(
            Math,
            filteredArray.map((x) => x)
          );
          rowNumber = rowNumber + 1;
        }
      }
      const testDate = moment(carBodyProtocol?.carTest.startAt);
      defect = {
        protocolRuleId: protocolRuleId,
        signDefect: undefined,
        assignedWorkers: [],
        expertGroupCode: carBodyProtocol?.carTest.expertGroupCode,
        carTestDate: testDate.toDate(),
        description: undefined,
        analysis: undefined,
        remedy: undefined,
        deadline: undefined,
        responsible: undefined,
        state: undefined,
        rownumber: rowNumber,
        partName: partName,
        isDeleted: false,
      };
    }
    openModal(
      <DefectForm
        isEdit={edit}
        defect={defect}
        enums={enums}
        values={values}
        handleDefectForm={handleDefectForm}
      />,
      {
        preventClosing: true,
        title: "Komentáře k závadám",
      }
    );
  };

  const handleDefectForm = (defect: IDefect, values?: ICarBodyProtocol) => {
    addOrUpdateDefect(defect, values);
  };

  const addOrUpdateDefect = (defect: IDefect, values?: ICarBodyProtocol) => {
    let existingDefect = values?.defects?.find(
      (x) => x.rownumber == defect.rownumber
    );
    if (existingDefect) {
      const index = values?.defects?.findIndex(
        (x) => x.rownumber == defect.rownumber
      );
      if (values?.defects && index) {
        values.defects[index] = defect;
        setDefects([...values.defects]);
      }
    } else {
      setDefects([...defects, defect]);
    }
  };

  const handleAddDefect = (values: ICarBodyProtocol) => {
    var rowNumber: number = 0;
    if (defects && defects.length > 0) {
      const filteredArray = defects
        .filter((x) => x.rownumber !== undefined)
        .map((x) => (x.rownumber ? x.rownumber : 0));
      if (filteredArray && filteredArray.length > 0) {
        rowNumber = Math.max.apply(
          Math,
          filteredArray.map((x) => x)
        );
        rowNumber = rowNumber + 1;
      }
    }
    const testDate = moment(carBodyProtocol?.carTest.startAt);
    const defect: IDefect = {
      signDefect: undefined,
      assignedWorkers: [],
      expertGroupCode: carBodyProtocol?.carTest.expertGroupCode,
      carTestDate: testDate.toDate(),
      description: undefined,
      analysis: undefined,
      remedy: undefined,
      deadline: undefined,
      responsible: undefined,
      state: undefined,
      rownumber: rowNumber,
      partName: undefined,
      isDeleted: false,
    };
    addOrUpdateDefect(defect, values);
  };

  const handleRemoveDefect = (
    rowNumber: number | undefined,
    values: ICarBodyProtocol
  ) => {
    if (rowNumber === undefined) return;
    var defect = values.defects?.find((p) => p.rownumber == rowNumber);
    if (defect) {
      defect.isDeleted = true;
      addOrUpdateDefect(defect, values);
    }
  };

  const existsAttachments = attachments && attachments?.length > 0;
  const uploadPanelSize = existsAttachments ? 6 : 12;

  const deleteAttachment = (attachmentId: number) => {
    if(attachments){
      setAttachments(attachments.filter((a) => a.attachmentId !== attachmentId));
    }
  }

  const addAttachment = (attachment: IAttachment) => {
    if(attachments)
    {
      setAttachments((prev) => [...prev!, attachment]);
    }
    else{
      const newArrayAttachments: IAttachment[] = [];
      newArrayAttachments.push(attachment);
      setAttachments(newArrayAttachments)
    }
  }; 

  return (
    <div
      id="CarBodyProtocol"
      className="container"
      style={{ marginBottom: "3rem" }}
    >
      <div className="printheader">
        <div className="printheader__left">
          <div className="printheader--image">
            <img src={logo} height={80} />
          </div>
        </div>
        <div className="printheader__right">
          <div className="text--header">Zkouškový protokol</div>
          <div className="text--text">
            Pracovní skupina{" "}
            <span className="text--expert-group">
              {carBodyProtocol?.carTest.expertGroupName}
            </span>
          </div>
          <div className="text--text">
            <span>{carBodyProtocol?.placeCode}</span> <span>{carBodyProtocol?.placeDescription}</span>
          </div>
        </div>
      </div>

      <div className="printfooter">
        <Table>
          <tbody>
            <tr>
              <td colSpan={11}>
                {carBodyProtocol?.placeCode}{" "}
                {carBodyProtocol?.car?.modelVdsCode || ""} / 1_01 &emsp; ŠKODA
                AUTO a.s., Tř. Václava Klementa 869, 293 01 Mladá Boleslav II.,
                Česká republika
              </td>
            </tr>
            <tr>
              <td colSpan={3}>{carBodyProtocol?.documentName}</td>
              <td>{carBodyProtocol?.registrationNumber}</td>
              <td>{carBodyProtocol?.storageMethod}</td>
              <td>{carBodyProtocol?.shreddingSign}</td>
              <td colSpan={2}>{carBodyProtocol?.shreddingTriggerEvent}</td>
              <td>{carBodyProtocol?.classification}</td>
              <td>{carBodyProtocol?.storageLocation}</td>
            </tr>
          </tbody>
        </Table>
      </div>

      <table className="table-page-content">
        <thead>
          <tr>
            <td>
              {/* <!--place holder for the fixed-position header--> */}
              <div className="page-header-space"></div>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <FinalForm
                onSubmit={handleFinalFormSubmit}
                initialValues={carBodyProtocol}
                mutators={{
                  checkMeasuredValue: (
                    args,
                    state,
                    utils
                  ): boolean | undefined => {
                    const fieldName = args[0].currentTarget.name;
                    var areaIndex: number = -1;
                    var groupIndex: number = -1;
                    var ruleIndex: number = -1;
                    var measuredValIndex: number = -1;

                    fieldName.split(".").map((objName: string, i: number) => {
                      if (objName.includes("areas")) {
                        areaIndex = Number.parseInt(
                          objName
                            .replace("areas", "")
                            .replace("[", "")
                            .replace("]", "") || "-1"
                        );
                      }
                      if (objName.includes("protocolPartGroups")) {
                        groupIndex = Number.parseInt(
                          objName
                            .replace("protocolPartGroups", "")
                            .replace("[", "")
                            .replace("]", "") || "-1"
                        );
                      }
                      if (objName.includes("partRules")) {
                        ruleIndex = Number.parseInt(
                          objName
                            .replace("partRules", "")
                            .replace("[", "")
                            .replace("]", "") || "-1"
                        );
                      }
                      if (objName.includes("measuredValues")) {
                        measuredValIndex = Number.parseInt(
                          objName
                            .replace("measuredValues", "")
                            .replace("[", "")
                            .replace("]", "") || "-1"
                        );
                      }
                    });

                    var partRule: IProtocolPartRule | undefined;
                    var partRuleFieldName: string = "";
                    if (state.lastFormState?.values.areas && areaIndex >= 0) {
                      const area: IProtocolArea =
                        state.lastFormState?.values?.areas[areaIndex];
                      if (area.protocolPartGroups && groupIndex >= 0) {
                        const group = area.protocolPartGroups[groupIndex];
                        if (group.partRules && ruleIndex >= 0) {
                          partRule = group.partRules[ruleIndex];
                          partRuleFieldName = `areas[${areaIndex}].protocolPartGroups[${groupIndex}].partRules[${ruleIndex}]`;
                        }
                      }
                      if (area.partRules && ruleIndex >= 0) {
                        partRule = area.partRules[ruleIndex];
                        partRuleFieldName = `areas[${areaIndex}].partRules[${ruleIndex}]`;
                      }
                    }
                    if (partRule) {
                      var measuredValue: string = "";
                      const emptyItems = CheckAllItemsEmpty(
                        partRule.measuredValues
                      );
                      if (emptyItems) {
                        measuredValue = "";
                      } else if (
                        partRule.measuredValues &&
                        partRule.measuredValues?.length === 1 &&
                        !emptyItems
                      ) {
                        measuredValue = partRule.measuredValues[0];
                      } else if (
                        partRule.measuredValues &&
                        partRule.measuredValues?.length > 1 &&
                        !emptyItems
                      ) {
                        measuredValue = partRule.measuredValues.join(" ; ");
                      }
                      const measuredValueField =
                        partRuleFieldName + ".measuredValue";
                      utils.changeValue(
                        state,
                        measuredValueField,
                        () => measuredValue
                      );
                      let validationResult = validatePartRules(partRule);

                      var val: string = "";
                      if (validationResult === true) {
                        val = "NOK";
                      } else if (validationResult === false) {
                        val = "OK";
                      }
                      const evalField = partRuleFieldName + ".stringEval";
                      utils.changeValue(state, evalField, () => val);
                      return validationResult;
                    }
                    return undefined;
                  },
                  calculateDefects: (args, state, utils) => {
                    var defects: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                    state.lastFormState?.values.areas?.forEach((area) => {
                      area.protocolPartGroups?.forEach((group) => {
                        group.partRules?.forEach((rule) => {
                          if (rule.signDefect) {
                            defects[rule.signDefect - 1] =
                              defects[rule.signDefect - 1] + 1;
                          }
                        });
                      });
                      area.partRules?.forEach((rule) => {
                        if (rule.signDefect) {
                          defects[rule.signDefect - 1] =
                            defects[rule.signDefect - 1] + 1;
                        }
                      });
                    });
                    let count = defects.reduce((a, b) => a + b, 0);
                    let countByNumber = `1 - ${defects[0]}; 2 - ${defects[1]}; 3 - ${defects[2]}; 4 - ${defects[3]}; 5 - ${defects[4]}; 6 - ${defects[5]}; 7 - ${defects[6]}; 8 - ${defects[7]}; 9 - ${defects[8]};`;
                    let countByCategory = `A1 - ${defects[0]}; A - ${
                      defects[1] + defects[2]
                    }; B1 - ${defects[3]}; B - ${defects[4]}; C1 - ${
                      defects[5]
                    }; C - ${defects[6] + defects[7] + defects[8]};`;
                    utils.changeValue(state, "defectsCount", () =>
                      count.toString()
                    );
                    utils.changeValue(
                      state,
                      "defectsCountByNumber",
                      () => countByNumber
                    );
                    utils.changeValue(
                      state,
                      "defectsCountByCategory",
                      () => countByCategory
                    );
                  },
                }}
                render={({ handleSubmit, values, form }) => (
                  <Form onSubmit={handleSubmit} id="protocol-form">
                    <div className="pageheader  no-print">
                      <div className="pageheader__left">
                        <h1>Zkouškový protokol</h1>
                        <h3>
                          Pracovní skupina{" "}
                          <span className="text--expert-group">
                            {carBodyProtocol?.carTest.expertGroupName}
                          </span>{" "}
                        </h3>
                      </div>
                      <div className="pageheader__right">
                        <Can
                          roleId={user?.userRoleId}
                          perform="car-body-protocol:edit"
                          data={{
                            userExpertGroupId: user?.expertGroupId,
                          }}
                          yes={
                            <EditSaveResetButtons
                              edit={edit}
                              setEdit={setEdit}
                              handleReset={handleReset}
                              form={form}
                              isSaving={false}
                              printButton={true}
                            />
                          }
                          no={
                            <Button
                              variant="outline-info"
                              type="button"
                              onClick={() => window.print()}
                            >
                              <FontAwesomeIcon icon={faPrint} title="Tisk" />{" "}
                              Tisk
                            </Button>
                          }
                        />

                        {!edit && (
                          <Fragment>

                            <Button
                              variant="outline-info"
                              type="button"
                              style={{
                                marginLeft: "1rem",
                              }}
                              onClick={() => handleGetDocument()}
                            >
                              <FontAwesomeIcon icon={faFileExcel} title="XLSX" />{" "}
                              XLSX
                            </Button>

                            <Button
                              variant="outline-secondary"
                              style={{
                                marginLeft: "1rem",
                              }}
                              key={"CarDetail_" + carBodyProtocol?.car.carId}
                              as={Link}
                              to={`/car/${carBodyProtocol?.car.carId}`}
                            >
                              Detail vozu
                            </Button>
                            <Button
                              variant="outline-secondary"
                              style={{
                                marginLeft: "1rem",
                              }}
                              key={
                                "CarTest_" + carBodyProtocol?.carTest.carTestId
                              }
                              as={Link}
                              to={`/carTest/${carBodyProtocol?.carTest.carTestId}`}
                            >
                              Detail zkoušky
                            </Button>
                          </Fragment>
                        )}
                      </div>
                    </div>

                    <AlertMessage
                        type="danger"
                        heading="Nastala chyba při ukládání protokolu"
                        show={showSubmitError}
                        setShow={setShowSubmitError}
                        messageList={errors}
                    />

                    <div className="pagecontent">
                      {!isLoading && carBodyProtocol && (
                        <ProtocolHeaderCarInfo
                          car={carBodyProtocol.car}
                          carTest={carBodyProtocol.carTest}
                          measurementDate={undefined}
                        />
                      )}
                      
                      <div className="protocol">

                        {!isLoading && carBodyProtocol && (

                        <Fragment>

                        {existsCarProtocolId &&
                          <Fragment>
                              <h2>Přílohy{" "}
                                <button
                                  onClick={() => setAttachmentsCollapsed(!attachmentsCollapsed)}
                                  type="button"
                                  aria-expanded={attachmentsCollapsed}
                                  className={`btn-link btn-link--primary animate-${
                                    attachmentsCollapsed ? "up" : "down"
                                  }`}
                                >
                                  {!attachmentsCollapsed && <FontAwesomeIcon icon={faChevronDown} />}
                                  {attachmentsCollapsed && <FontAwesomeIcon icon={faChevronUp} />}
                                </button>
                              </h2>

                              <Collapse in={attachmentsCollapsed}>
                                <Row>
                                  <AttachmentsList attachments={attachments} colSize={uploadPanelSize} deleteAttachments={deleteAttachment} />

                                  <Col md={uploadPanelSize}>
                                    <MultiFilesUploaderPanel carId={carId} carTestId={carTestId} carProtocolId={carProtocolId} carAnalysisId={undefined} carDefectId={undefined} addAttachment={addAttachment} />
                                  </Col>
                                </Row>
                              </Collapse>
                            </Fragment>
                          }

                          <Table
                            className="ProtocolAddInfo"
                            style={{ marginTop: "0", fontSize: "0.9em" }}
                          >
                            <tbody>
                              <tr className={`protocol-group`}>
                                <td>
                                  <Form.Group controlId={"controlEquipment"}>
                                    <Row>
                                      <Col xs={4}>
                                        <Form.Label>
                                          Kontrolní zařízení
                                        </Form.Label>
                                      </Col>
                                      <Col xs={7}>
                                        <Field<string>
                                          name="controlEquipment"
                                          initialValue={
                                            carBodyProtocol?.controlEquipment
                                          }
                                          component={SimpleTextInput}
                                          disabled={!edit}
                                        />
                                      </Col>
                                    </Row>
                                  </Form.Group>
                                </td>
                                <td>
                                  <Form.Group controlId={"temperature"}>
                                    <Row>
                                      <Col xs={4}>
                                        <Form.Label>Teplota vzduchu</Form.Label>
                                      </Col>
                                      <Col xs={7}>
                                        <Field<string>
                                          name="temperature"
                                          initialValue={
                                            carBodyProtocol?.temperature
                                          }
                                          component={SimpleTextInput}
                                          disabled={!edit}
                                        />
                                      </Col>
                                    </Row>
                                  </Form.Group>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                          </Fragment>
                        )}
                        <Table id="protocol-table">
                          <thead className="print-only">
                            <tr
                              className={`protocol-rule protocol-rule--header`}
                            >
                              <td>Oblast</td>
                              <td>Měřený díl a poloha</td>
                              <td>
                                <h4>Předpis</h4>
                                <small className="text-muted">
                                  [N;Nm;Ncm;mm;m/s] subj.
                                </small>
                              </td>
                              <td>
                                <h4>Naměřeno</h4>
                                <small className="text-muted">
                                  [N;Nm;Ncm;mm;m/s] subj.
                                </small>
                              </td>
                              <td>
                                <h4>Hodnocení</h4>
                                <small className="text-muted">
                                  Vyhovuje / Závada
                                </small>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {!isLoading &&
                              carBodyProtocol &&
                              carBodyProtocol.areas
                                ?.slice()
                                .sort((a, b) => a.order - b.order)
                                .map((protocolArea, areaIndex) => {
                                  if (protocolArea.protocolPartGroups) {
                                    var printAreaNameCounter: number = 0;
                                    return protocolArea.protocolPartGroups?.map(
                                      (group, groupIndex) => {
                                        var printGrouAddInfoCounter: number = 0;
                                        return group.partRules?.map(
                                          (rule, ruleIndex) => {
                                            let result: JSX.Element[] = [];

                                            // radek s oblasti
                                            if (printedArea !== areaIndex) {
                                              let areaRow = (
                                                <ProtocolArea
                                                  protocolArea={protocolArea}
                                                  areaIndex={
                                                    carBodyProtocol.areas?.findIndex(
                                                      (x) =>
                                                        x.protocolAreaId ===
                                                        protocolArea.protocolAreaId
                                                    )!
                                                  }
                                                  isEdit={edit}
                                                  isDisabled={
                                                    (values.areas &&
                                                      values.areas[areaIndex]
                                                        .notPerform) ||
                                                    false
                                                  }
                                                  key={
                                                    "A" +
                                                    protocolArea.protocolAreaId
                                                  }
                                                />
                                              );
                                              printedArea = areaIndex;
                                              result.push(areaRow);
                                            }

                                            // radek se skupinou
                                            if (printedGroup !== groupIndex) {
                                              let groupRow = (
                                                <ProtocolGroup
                                                  protocolPartGroup={group}
                                                  areaIndex={areaIndex}
                                                  groupPartIndex={
                                                    protocolArea.protocolPartGroups?.findIndex(
                                                      (x) =>
                                                        x.protocolPartGroupId ===
                                                        group.protocolPartGroupId
                                                    )!
                                                  }
                                                  isEdit={edit}
                                                  isDisabled={
                                                    (values.areas &&
                                                      values.areas[areaIndex]
                                                        .notPerform) ||
                                                    (values.areas &&
                                                      values.areas[areaIndex]
                                                        .protocolPartGroups &&
                                                      values.areas[areaIndex]
                                                        .protocolPartGroups![
                                                        groupIndex
                                                      ].notPerform) ||
                                                    false
                                                  }
                                                  key={
                                                    "G" +
                                                    group.protocolPartGroupId
                                                  }
                                                />
                                              );
                                              printedGroup = groupIndex;
                                              result.push(groupRow);
                                            }

                                            // radek s pravidlem
                                            if (
                                              !(
                                                (values.areas &&
                                                  values.areas[areaIndex]
                                                    .notPerform) ||
                                                (values.areas &&
                                                  values.areas[areaIndex]
                                                    .protocolPartGroups &&
                                                  values.areas[areaIndex]
                                                    .protocolPartGroups![
                                                    groupIndex
                                                  ].notPerform) ||
                                                (values.areas &&
                                                  values.areas[areaIndex]
                                                    .protocolPartGroups &&
                                                  values.areas[areaIndex]
                                                    .protocolPartGroups![
                                                    groupIndex
                                                  ].partRules &&
                                                  values.areas[areaIndex]
                                                    .protocolPartGroups![
                                                    groupIndex
                                                  ].partRules![ruleIndex]
                                                    .notPerform)
                                              )
                                            ) {
                                              printAreaNameCounter++;
                                              printGrouAddInfoCounter++;
                                            }

                                            let ruleRow = (
                                              <ProtocolRule
                                                partRule={rule}
                                                area={protocolArea}
                                                group={group}
                                                useGroupAddInfo={
                                                  printGrouAddInfoCounter === 1
                                                }
                                                fieldName={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].partRules[${ruleIndex}]`}
                                                ruleIndex={ruleIndex}
                                                isEdit={edit}
                                                isDisabledAreaOrGroup={
                                                  (values.areas &&
                                                    values.areas[areaIndex]
                                                      .notPerform) ||
                                                  (values.areas &&
                                                    values.areas[areaIndex]
                                                      .protocolPartGroups &&
                                                    values.areas[areaIndex]
                                                      .protocolPartGroups![
                                                      groupIndex
                                                    ].notPerform) ||
                                                  false
                                                }
                                                isDisabledRule={
                                                  (values.areas &&
                                                    values.areas[areaIndex]
                                                      .notPerform) ||
                                                  (values.areas &&
                                                    values.areas[areaIndex]
                                                      .protocolPartGroups &&
                                                    values.areas[areaIndex]
                                                      .protocolPartGroups![
                                                      groupIndex
                                                    ].notPerform) ||
                                                  (values.areas &&
                                                    values.areas[areaIndex]
                                                      .protocolPartGroups &&
                                                    values.areas[areaIndex]
                                                      .protocolPartGroups![
                                                      groupIndex
                                                    ].partRules &&
                                                    values.areas[areaIndex]
                                                      .protocolPartGroups![
                                                      groupIndex
                                                    ].partRules![ruleIndex]
                                                      .notPerform) ||
                                                  false
                                                }
                                                key={rule.protocolRuleId}
                                                useAreaName={
                                                  printAreaNameCounter === 1
                                                }
                                                useDoorProtect={
                                                  (printAreaNameCounter === 2 &&
                                                    protocolArea.enableDoorProtect &&
                                                    values.areas &&
                                                    values.areas[areaIndex]
                                                      .doorProtect) ||
                                                  false
                                                }
                                                form={form}
                                                values={values}
                                                handleOpenModelDefectForm={
                                                  handleOpenModelDefectForm
                                                }
                                              />
                                            );
                                            result.push(ruleRow);
                                            return result;
                                          }
                                        );
                                      }
                                    );
                                  }
                                  if (protocolArea.partRules) {
                                    var printAreaNameCounter: number = 0;
                                    return protocolArea.partRules?.map(
                                      (rule, ruleIndex) => {
                                        let result: JSX.Element[] = [];
                                        // radek s oblasti
                                        if (printedArea !== areaIndex) {
                                          let areaRow = (
                                            <ProtocolArea
                                              key={protocolArea.protocolAreaId}
                                              protocolArea={protocolArea}
                                              areaIndex={
                                                carBodyProtocol.areas?.findIndex(
                                                  (x) =>
                                                    x.protocolAreaId ===
                                                    protocolArea.protocolAreaId
                                                )!
                                              }
                                              isEdit={edit}
                                              isDisabled={
                                                (values.areas &&
                                                  values.areas[areaIndex]
                                                    .notPerform) ||
                                                false
                                              }
                                            />
                                          );
                                          printedArea = areaIndex;
                                          result.push(areaRow);
                                        }

                                        // radek s pravidlem
                                        if (
                                          !(
                                            (values.areas &&
                                              values.areas[areaIndex]
                                                .notPerform) ||
                                            (values.areas &&
                                              values.areas[areaIndex]
                                                .partRules &&
                                              values.areas[areaIndex]
                                                .partRules![ruleIndex]
                                                .notPerform)
                                          )
                                        ) {
                                          printAreaNameCounter++;
                                        }

                                        let ruleRow = (
                                          <ProtocolRule
                                            partRule={rule}
                                            area={protocolArea}
                                            group={undefined}
                                            useGroupAddInfo={false}
                                            fieldName={`areas[${areaIndex}].partRules[${ruleIndex}]`}
                                            ruleIndex={ruleIndex}
                                            isEdit={edit}
                                            isDisabledAreaOrGroup={
                                              (values.areas &&
                                                values.areas[areaIndex]
                                                  .notPerform) ||
                                              false
                                            }
                                            isDisabledRule={
                                              (values.areas &&
                                                values.areas[areaIndex]
                                                  .notPerform) ||
                                              (values.areas &&
                                                values.areas[areaIndex]
                                                  .partRules &&
                                                values.areas[areaIndex]
                                                  .partRules![ruleIndex]
                                                  .notPerform) ||
                                              false
                                            }
                                            key={rule.protocolRuleId}
                                            useAreaName={
                                              printAreaNameCounter === 1
                                            }
                                            useDoorProtect={false}
                                            form={form}
                                            values={values}
                                            handleOpenModelDefectForm={
                                              handleOpenModelDefectForm
                                            }
                                          />
                                        );
                                        result.push(ruleRow);
                                        return result;
                                      }
                                    );
                                  }
                                  return null;
                                })}
                          </tbody>
                        </Table>

                        {/*Poznamka k celkovemu protokolu*/}
                        {!isLoading && carBodyProtocol && (
                          <div style={{ paddingTop: "1em" }}>
                            <h2>Poznámka</h2>
                            <Field<string>
                              name="note"
                              initialValue={carBodyProtocol.note}
                              component={TextAreaInput}
                              disabled={!edit}
                              rows={5}
                            />
                          </div>
                        )}
                        {/*Tabulka zavad*/}
                        {!isLoading &&
                          carBodyProtocol &&
                          carBodyProtocol?.defectsArea && (
                            <CarBodyDefectsArea
                              isEdit={edit}
                              defects={defects}
                              defectsArea={carBodyProtocol?.defectsArea}
                              enums={enums}
                              isDisabled={
                                values.defectsArea?.notPerform || false
                              }
                              form={form}
                              values={values}
                              handleAddDefect={handleAddDefect}
                              handleRemoveDefect={handleRemoveDefect}
                            />
                          )}
                        {/*Počet závad*/}
                        {!isLoading && carBodyProtocol && (
                          <Table
                            className="ProtocolAddInfo"
                            style={{ marginTop: "0", fontSize: "0.9em" }}
                          >
                            <tbody>
                              <tr className={`protocol-group`}>
                                <td style={{ borderTop: "none" }}>
                                  <Form.Group controlId={"defectsCount"}>
                                    <Row>
                                      <Col xs={3}>
                                        <Form.Label>Počet závad</Form.Label>
                                      </Col>
                                      <Col xs={5}>
                                        <Field<string>
                                          name="defectsCount"
                                          initialValue={
                                            carBodyProtocol?.defectsCount
                                          }
                                          component={TextInput}
                                          readonly
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xs={3}>
                                        <Form.Label>z toho -</Form.Label>
                                      </Col>
                                      <Col xs={5}>
                                        <Field<string>
                                          name="defectsCountByNumber"
                                          initialValue={
                                            carBodyProtocol?.defectsCountByNumber
                                          }
                                          component={TextInput}
                                          disabled={!edit}
                                          readonly
                                        />
                                        <Field<string>
                                          name="defectsCountByCategory"
                                          initialValue={
                                            carBodyProtocol?.defectsCountByCategory
                                          }
                                          component={TextInput}
                                          disabled={!edit}
                                          readonly
                                        />
                                      </Col>
                                    </Row>
                                  </Form.Group>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        )}
                        {!isLoading && carBodyProtocol && (
                          <Table style={{ marginTop: "50px" }}>
                            <tbody>
                              <tr>
                                <td style={{ border: "0" }}>
                                  <Form.Group controlId={"defectsCount"}>
                                    <Row>
                                      <Col xs={3}>
                                        <Form.Label>
                                          Odpovědná osoba:
                                        </Form.Label>
                                      </Col>
                                      <Col
                                        xs={5}
                                        style={{
                                          textAlign: "center",
                                          borderBottom: "1px solid lightgray",
                                        }}
                                      >
                                        <Form.Label>
                                          {carBodyProtocol.workersExecutingTest}
                                        </Form.Label>
                                      </Col>
                                    </Row>
                                  </Form.Group>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        )}
                      </div>
                    </div>
                  </Form>
                )}
              />
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td>
              <div className="page-footer-space"></div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default observer(CarBodyProtocol);
