import React, { Fragment } from "react";
import { Row, Col, Table, Container } from "react-bootstrap";
import { Field, useForm } from "react-final-form";
import { IEngineUnitAndChassisProtocol, IProtocolPartGroup, IEngineUnitAndChassisAxleLoadWeightsGroupDataExtension } from "../../../../../app/models/protocol";
import CheckboxInput from "../../../../../app/common/form/CheckboxInput";
import TextInput from "../../../../../app/common/form/TextInput";
import NumberInput from "../../../../../app/common/form/NumberInput";

interface IProps {
    protocol: IEngineUnitAndChassisProtocol;
    group: IProtocolPartGroup;
    areaIndex: number;
    groupIndex: number;
    isEdit: boolean;
    isDisabledArea: boolean;
    isDisabledGroup: boolean;
}

const CheckAxleLoadWeightsGroup: React.FC<IProps> = ({ protocol, group, areaIndex, groupIndex, isEdit, isDisabledArea, isDisabledGroup }) => {

    const form = useForm<IEngineUnitAndChassisProtocol>();
    const dataExtension: IEngineUnitAndChassisAxleLoadWeightsGroupDataExtension = group.dataExtension;
    let isDisabled: boolean = isDisabledArea || isDisabledGroup;
    
    return (
      <Fragment>
        <Container className={`pt-2 ${ isDisabled ? "disabled" : ""}`} key={"CheckAxleLoadWeightsGroup"}>
            <Row noGutters className={'mt-2 mb-2 border-bottom'}>
                <Col sm={6}>
                    <h3>{group.name}</h3>
                </Col>
                <Col sm={2}></Col>
                <Col sm={2}></Col>
                <Col sm={2}>
                    <Field
                        name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].notPerform`}
                        title="Neprovádět"
                        options={[
                            {key: 1, value: "ano"},
                            {key: 0, value: " ne "},
                        ]}
                        format={(v) => (v ? 1 : 0)}
                        parse={(v) => (!!v)}
                        type={"checkbox"}
                        initialValue={group.notPerform || false}
                        component={CheckboxInput}
                        inputOnChange={(e: any) => {
                            form.mutators.handleChangePerformAreaOrGroup(e);
                        }}
                        disabled={!isEdit || isDisabledArea}
                        labelWidth={8}
                        valueWidth={4}
                    />
                </Col>
            </Row>

            <Row>
                <Col sm={12}>
                    <h4>Specifikace</h4>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <Table className="weights">
                        <thead>
                            <tr>
                                <th>Měřený díl</th>
                                <th>PR číslo</th>
                                <th>Neodpružená hmotnost (PN)</th>
                                <th>Neodpružená hmotnost (ZN)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataExtension.driveShowRow && 
                                <tr>
                                    <td>Pohon</td>
                                    <td>
                                        <Col sm={8} style={{paddingLeft: 0}}>
                                            <Field
                                                name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.drivePrNumber`}
                                                component={TextInput}
                                                disabled={true}
                                                size="sm"
                                            />
                                        </Col>
                                    </td>
                                    <td>
                                        <Row>
                                            <Col sm={8}>
                                                <Field
                                                    name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.driveUnsprungWeightFrontAxle`}
                                                    component={TextInput}
                                                    disabled={true}
                                                    size="sm"
                                                />
                                            </Col>
                                            <Col>Kg</Col>
                                        </Row>
                                    </td>
                                    <td>
                                        <Row>
                                            <Col sm={8}>
                                                <Field
                                                    name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.driveUnsprungWeightRearAxle`}
                                                    component={TextInput}
                                                    disabled={true}
                                                    size="sm"
                                                />
                                            </Col>
                                            <Col>Kg</Col>
                                        </Row>
                                    </td>
                                </tr>
                            }
                            {dataExtension.wheelsShowRow && 
                                <tr>
                                    <td>Kola</td>
                                    <td>
                                        <Col sm={8} style={{paddingLeft: 0}}>
                                            <Field
                                                name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.wheelsPrNumber`}
                                                component={TextInput}
                                                disabled={true}
                                                size="sm"
                                            />
                                        </Col>
                                    </td>
                                    <td>
                                        <Row>
                                            <Col sm={8}>
                                                <Field
                                                    name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.wheelsUnsprungWeightFrontAxle`}
                                                    component={TextInput}
                                                    disabled={true}
                                                    size="sm"
                                                />
                                            </Col>
                                            <Col>Kg</Col>
                                        </Row>
                                    </td>
                                    <td>
                                        <Row>
                                            <Col sm={8}>
                                                <Field
                                                    name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.wheelsUnsprungWeightRearAxle`}
                                                    component={TextInput}
                                                    disabled={true}
                                                    size="sm"
                                                />
                                            </Col>
                                            <Col>Kg</Col>
                                        </Row>
                                    </td>
                                </tr>
                            }
                            {dataExtension.brakesShowRow && 
                                <tr style={{ borderBottom: "2px solid #dee2e6" }}>
                                    <td>Brzdy</td>
                                    <td>
                                        <Col sm={8} style={{paddingLeft: 0}}>
                                            <Field
                                                name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.brakesPrNumber`}
                                                component={TextInput}
                                                disabled={true}
                                                size="sm"
                                            />
                                        </Col>
                                    </td>
                                    <td>
                                        <Row>
                                            <Col sm={8}>
                                                <Field
                                                    name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.brakesUnsprungWeightFrontAxle`}
                                                    component={TextInput}
                                                    disabled={true}
                                                    size="sm"
                                                />
                                            </Col>
                                            <Col>Kg</Col>
                                        </Row>
                                    </td>
                                    <td>
                                        <Row>
                                            <Col sm={8}>
                                                <Field
                                                    name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.brakesUnsprungWeightRearAxle`}
                                                    component={TextInput}
                                                    disabled={true}
                                                    size="sm"
                                                />
                                            </Col>
                                            <Col>Kg</Col>
                                        </Row>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <h4>Dovážení</h4>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <Table className="weights">
                        <thead>
                            <tr>
                                <th>Měřený díl</th>
                                <th>PR číslo</th>
                                <th>Naměřeno</th>
                                <th>Závaží</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataExtension.washersShowRow && 
                                <tr>
                                    <td>Ostřikovače</td>
                                    <td>
                                        <Col sm={8} style={{paddingLeft: 0}}>

                                        </Col>
                                    </td>
                                    <td>
                                        <Row>
                                            <Col sm={8}>
                                                <Field
                                                    name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.washersMeasured`}
                                                    component={TextInput}
                                                    disabled={!isEdit || isDisabled}
                                                    inputOnChange={(e: any) => {
                                                        form.mutators.calculateWasherWeights(e);
                                                    }}
                                                    size="sm"
                                                />
                                            </Col>
                                            <Col>l</Col>
                                        </Row>
                                    </td>
                                    <td>
                                        <Row>
                                            <Col sm={8}>
                                                <Field
                                                    name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.washersWeights`}
                                                    component={TextInput}
                                                    disabled={!isEdit || isDisabled}
                                                    size="sm"
                                                />
                                            </Col>
                                            <Col>Kg</Col>
                                        </Row>
                                    </td>
                                </tr>
                            }
                            {dataExtension.fuelShowRow &&
                                <tr>
                                    <td>Palivo</td>
                                    <td>
                                        <Col sm={8} style={{paddingLeft: 0}}>
                                            <Field
                                                name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.fuelPrNumber`}
                                                component={TextInput}
                                                disabled={true}
                                                size="sm"
                                            />
                                        </Col>
                                    </td>
                                    <td>
                                        <Row>
                                            <Col sm={8}>
                                                <Field
                                                    name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.fuelMeasured`}
                                                    component={TextInput}
                                                    disabled={!isEdit || isDisabled}
                                                    inputOnChange={(e: any) => {
                                                        form.mutators.calculateFuelWeights(e);
                                                    }}
                                                    size="sm"
                                                />
                                            </Col>
                                            <Col>l</Col>
                                        </Row>
                                    </td>
                                    <td>
                                        <Row>
                                            <Col sm={8}>
                                                <Field
                                                    name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.fuelWeights`}
                                                    component={TextInput}
                                                    disabled={!isEdit || isDisabled}
                                                    size="sm"
                                                />
                                            </Col>
                                            <Col>Kg</Col>
                                        </Row>
                                    </td>
                                </tr>
                            }
                            {dataExtension.adBlueShowRow && 
                                <tr style={{ borderBottom: "2px solid #dee2e6" }}>
                                    <td>Močovina</td>
                                    <td>
                                        <Col sm={8} style={{paddingLeft: 0}}>
                                            <Field
                                                name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.adBluePrNumber`}
                                                component={TextInput}
                                                disabled={true}
                                                size="sm"
                                            />
                                        </Col>
                                    </td>
                                    <td>
                                        <Row>
                                            <Col sm={8}>
                                                <Field
                                                    name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.adBlueMeasured`}
                                                    component={TextInput}
                                                    disabled={!isEdit || isDisabled}
                                                    inputOnChange={(e: any) => {
                                                        form.mutators.calculateAdBlueWeights(e);
                                                    }}
                                                    size="sm"
                                                />
                                            </Col>
                                            <Col>l</Col>
                                        </Row>
                                    </td>
                                    <td>
                                        <Row>
                                            <Col sm={8}>
                                                <Field
                                                    name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.adBlueWeights`}
                                                    component={TextInput}
                                                    disabled={!isEdit || isDisabled}
                                                    size="sm"
                                                />
                                            </Col>
                                            <Col>Kg</Col>
                                        </Row>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <h4>Měření hmotností</h4>
                </Col>
            </Row>
            <Row>
                <Table className="weights measurement" >
                    <tr>
                        <td><label>Levá přední</label></td>
                        <td><label>Pravá přední</label></td>
                        <td><label>Přední náprava</label></td>
                        <td><label>Bez neodpružené hmotnosti</label></td>
                    </tr>
                    <tr>
                        <td>
                            <Row>
                                <Col sm={8}>
                                    <Field
                                        name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.weightMeasureLeftFront`}
                                        component={NumberInput}
                                        disabled={!isEdit || isDisabled}
                                        inputOnChange={(e: any) => {
                                            form.mutators.calculateWeightMeasureFrontAxle(e);
                                        }}
                                        parse={(value) => value && parseFloat(value)}
                                        size="sm"
                                    />
                                </Col>
                                <Col>Kg</Col>
                            </Row>
                        </td>
                        <td>
                            <Row>
                                <Col sm={8}>
                                    <Field
                                        name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.weightMeasureRightFront`}
                                        component={NumberInput}
                                        disabled={!isEdit || isDisabled}
                                        inputOnChange={(e: any) => {
                                            form.mutators.calculateWeightMeasureFrontAxle(e);
                                        }}
                                        parse={(value) => value && parseFloat(value)}
                                        size="sm"
                                    />
                                </Col>
                                <Col>Kg</Col>
                            </Row>
                        </td>
                        <td>
                            <Row>
                                <Col sm={8}>
                                    <Field
                                        name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.weightMeasureFrontAxle`}
                                        component={TextInput}
                                        disabled={!isEdit || isDisabled}
                                        size="sm"
                                    />
                                </Col>
                                <Col>Kg</Col>
                            </Row>
                        </td>
                        <td>
                            <Row>
                                <Col sm={8}>
                                    <Field
                                        name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.unsprungWeightFrontAxle`}
                                        component={TextInput}
                                        disabled={!isEdit || isDisabled}
                                        size="sm"
                                    />
                                </Col>
                                <Col>Kg</Col>
                            </Row>
                        </td>
                    </tr>
                    <tr>
                        <td><label>Levá zadní</label></td>
                        <td><label>Pravá zadní</label></td>
                        <td><label>Zadní náprava</label></td>
                        <td><label>Bez neodpružené hmotnosti</label></td>
                    </tr>
                    <tr>
                        <td>
                            <Row>
                                <Col sm={8}>
                                    <Field
                                        name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.weightMeasureLeftRear`}
                                        component={TextInput}
                                        disabled={!isEdit || isDisabled}
                                        inputOnChange={(e: any) => {
                                            form.mutators.calculateWeightMeasureRearAxle(e);
                                        }}
                                        parse={(value) => value && parseFloat(value)}
                                        size="sm"
                                    />
                                </Col>
                                <Col>Kg</Col>
                            </Row>
                        </td>
                        <td>
                            <Row>
                                <Col sm={8}>
                                    <Field
                                        name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.weightMeasureRightRear`}
                                        component={TextInput}
                                        disabled={!isEdit || isDisabled}
                                        inputOnChange={(e: any) => {
                                            form.mutators.calculateWeightMeasureRearAxle(e);
                                        }}
                                        parse={(value) => value && parseFloat(value)}
                                        size="sm"
                                    />
                                </Col>
                                <Col>Kg</Col>
                            </Row>
                        </td>
                        <td>
                            <Row>
                                <Col sm={8}>
                                    <Field
                                        name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.weightMeasureRearAxle`}
                                        component={TextInput}
                                        disabled={!isEdit || isDisabled}
                                        size="sm"
                                    />
                                </Col>
                                <Col>Kg</Col>
                            </Row>
                        </td>
                        <td>
                            <Row>
                                <Col sm={8}>
                                    <Field
                                        name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.unsprungWeightRearAxle`}
                                        component={TextInput}
                                        disabled={!isEdit || isDisabled}
                                        size="sm"
                                    />
                                </Col>
                                <Col>Kg</Col>
                            </Row>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td><label>Celková hmotnost</label></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>
                            <Row>
                                <Col sm={8}>
                                    <Field
                                        name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.weightMeasureTotal`}
                                        component={TextInput}
                                        disabled={!isEdit || isDisabled}
                                        size="sm"
                                    />
                                </Col>
                                <Col>Kg</Col>
                            </Row>
                        </td>
                        <td></td>
                    </tr>
                </Table>
            </Row>
            <Row>
                <Col sm={12}>
                    <h4>IFA hmotnosti</h4>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <Table className="weights ifa">
                        <tr style={{ borderBottom: "2px solid #dee2e6" }}>
                            <td colSpan={12}><label>Odpružená hmotnost</label></td>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                                <label>Přední náprava</label>
                            </td>
                            <td colSpan={3}>
                                <label>Zadní náprava</label>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                                <Row>
                                    <Col sm={8}>
                                        <Field
                                            name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.ifaSprungWeightFrontAxle`}
                                            component={TextInput}
                                            disabled={!isEdit || isDisabled}
                                            size="sm"
                                        />
                                    </Col>
                                    <Col>Kg</Col>
                                </Row>
                            </td>
                            <td colSpan={3}>
                                <Row>
                                    <Col sm={8}>
                                        <Field
                                            name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.ifaSprungWeightRearAxle`}
                                            component={TextInput}
                                            disabled={!isEdit || isDisabled}
                                            size="sm"
                                        />
                                    </Col>
                                    <Col>Kg</Col>
                                </Row>
                            </td>
                        </tr>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <Table className="weights ifa">
                        <tr style={{ borderBottom: "1px solid #dee2e6" }}>
                            <td colSpan={12}><label>Neodpružená hmotnost</label></td>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                                <label>Přední náprava</label>
                            </td>
                            <td colSpan={3}>
                                <label>Zadní náprava</label>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                                <Row>
                                    <Col sm={8}>
                                        <Field
                                            name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.ifaUnsprungWeightFrontAxle`}
                                            component={TextInput}
                                            disabled={!isEdit || isDisabled}
                                            size="sm"
                                        />
                                    </Col>
                                    <Col>Kg</Col>
                                </Row>
                            </td>
                            <td colSpan={3}>
                                <Row>
                                    <Col sm={8}>
                                        <Field
                                            name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.ifaUnsprungWeightRearAxle`}
                                            component={TextInput}
                                            disabled={!isEdit || isDisabled}
                                            size="sm"
                                        />
                                    </Col>
                                    <Col>Kg</Col>
                                </Row>
                            </td>
                        </tr>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col sm={12}><hr/></Col>
            </Row>
        </Container>
      </Fragment>
    );
  };
  
  export default CheckAxleLoadWeightsGroup;