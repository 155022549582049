import {RootStore} from "./rootStore";
import {action, observable, runInAction} from "mobx";
import {
  AreaEnumDTO,
  GroupEnumDTO,
  ICarBodyProtocol,
  IEecProtocol,
  IElectroProtocol,
  IMainProtocol,
  IPartSettingDTO,
  IProtocolAreasSetting,
  IProtocolCopyRulesDTO,
  IProtocolPartGroupsSetting,
  IProtocolTypeInfo,
  IWaterResistanceProtocol,
  PartEnumDTO,
  IProtocolRuleConditionSettingDTO,
  IProtocolRuleConditionPrRelationSettingDTO
} from "../models/protocol";
import agent from "../api/agent";
import {toast} from "react-toastify";

export default class ProtocolStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable carBodyProtocolRegistry = new Map<string, ICarBodyProtocol>();
  @observable electroProtocolRegistry = new Map<string, IElectroProtocol>();
  @observable waterResistanceProtocolRegistry = new Map<string, IWaterResistanceProtocol>();
  @observable ehkProtocolRegistry = new Map<string, IEecProtocol>();
  @observable mainProtocolRegistry = new Map<number, IMainProtocol>();
  @observable protocolTypeInfoRegistry = new Map<number, IProtocolTypeInfo>();
  @observable protocolAreaRegistry = new Map<number, IProtocolAreasSetting>();
  @observable protocolPartGroupRegistry = new Map<number, IProtocolPartGroupsSetting>();
  @observable protocolAreaEnumsRegistry = new Map<number, AreaEnumDTO[]>();
  @observable protocolGroupEnumsRegistry = new Map<number, GroupEnumDTO[]>();
  @observable protocolPartEnumsRegistry = new Map<number, PartEnumDTO[]>();

  @observable isLoading: boolean = false;
  @action loadCarBodyProtocol = async (
    carId: number,
    carTestId: number,
    protocolTypeId: number,
    carProtocolId?: number
  ) => {

    try {
      const protocol: ICarBodyProtocol | undefined = this.carBodyProtocolRegistry.get(`C${carId}-CT${carTestId}-PT${protocolTypeId}`)
      if(protocol === undefined){
        this.isLoading = true;
        const response = await agent.Protocols.carBodyProtocol(
          carId,
          carTestId,
          protocolTypeId,
          carProtocolId
        );
        //console.log(response);
        runInAction("load protocol data", () => {
          this.carBodyProtocolRegistry.set(`C${carId}-CT${carTestId}-PT${protocolTypeId}`, response);
          this.isLoading = false;
        });
      }
    } catch (error) {
      runInAction("load protocol data error", () => {
        //console.log(error);
        this.isLoading = false;
      });
      throw error;
    }
  };

  @action loadElectroProtocol = async (
    carId: number,
    carTestId: number,
    protocolTypeId: number,
    carProtocolId?: number
  ) => {

    try {
      const elProtocvol: IElectroProtocol | undefined = this.electroProtocolRegistry.get(`C${carId}-CT${carTestId}-PT${protocolTypeId}`);
      if(elProtocvol === undefined){
        this.isLoading = true;
        const response = await agent.Protocols.electroProtocol(
          carId,
          carTestId,
          protocolTypeId,
          carProtocolId
        );
        runInAction("load protocol data", () => {
          this.electroProtocolRegistry.set(`C${carId}-CT${carTestId}-PT${protocolTypeId}`, response);
          this.isLoading = false;
        });
      }
    } catch (error) {
      runInAction("load protocol data error", () => {
        //console.log(error);
        this.isLoading = false;
      });
      throw error;
    }
  };

  @action loadEHKProtocol = async (
    carId: number,
    protocolTypeId: number,
    carTestId?: number | undefined,
    carAnalysisId?: number | undefined,
    carProtocolId?: number
  ) => {

    try {
        this.isLoading = true;
        const response = await agent.Protocols.eccProtocol(
          carId,
          protocolTypeId,
          carTestId,
          carAnalysisId,
          carProtocolId
        );
        runInAction("load protocol data", () => {
          this.isLoading = false;
        });
        return response;
    } catch (error) {
      runInAction("load protocol data error", () => {

        this.isLoading = false;
      });
      throw error;
    }
  };

  @action saveCarBodyProtocol = async (protocol: ICarBodyProtocol) => {
    try {
      this.isLoading = true;
      //console.log(protocol);
      const response = await agent.Protocols.updateCarBodyProtocol(protocol);
      toast.success(`Protokol byl uložen`);
      runInAction("update protocol data", () => {
        this.carBodyProtocolRegistry.set(`C${response.carId}-CT${response.carTestId}-PT${response.protocolTypeId}`, response);
        this.isLoading = false;
      });
      return response;
    } catch (error) {
      console.log(error);
      toast.error("Při ukládání protokolu nastala chyba!");
      this.isLoading = false;
      throw error;
    }
  };

  @action saveElectroProtocol = async (protocol: IElectroProtocol) => {
    try {
      this.isLoading = true;
      //console.log(protocol);
      const response = await agent.Protocols.updateElectroProtocol(protocol);
      toast.success(`Protokol byl uložen`);
      runInAction("update protocol data", () => {
        this.electroProtocolRegistry.set(`C${response.carId}-CT${response.carTestId}-PT${response.protocolTypeId}`, response);
        this.isLoading = false;
      });
      return response;
    } catch (error) {
      console.log(error);
      toast.error("Při ukládání protokolu nastala chyba!");
      this.isLoading = false;
      throw error;
    }
  };

  @action saveEHKProtocol = async (protocol: IEecProtocol) => {
    try {
      this.isLoading = true;
      const response = await agent.Protocols.updateEECProtocol(protocol);
      toast.success(`Protokol byl uložen`);
      runInAction("update protocol data", () => {
        this.ehkProtocolRegistry.set(`PT${response.protocolTypeId}-C${response.carId}-CT${response.carTestId}-CT${response.carAnalysisId}`, response);
        this.isLoading = false;
      });
      return response;

    } catch (error) {
      console.log(error);
      toast.error("Při ukládání protokolu nastala chyba!");
      this.isLoading = false;
      throw error;
    }
  };

  @action loadMainProtocol = async (carId: number, carProtocolId: number) => {
    this.isLoading = true;
    try {
      const response = await agent.Protocols.mainProtocol(carId, carProtocolId);
      // console.log(response);
      runInAction("load protocol data", () => {
        this.mainProtocolRegistry.set(response.carId, response);
        this.isLoading = false;
      });
    } catch (error) {
      runInAction("load main protocol data error", () => {
        console.log(error);
        this.isLoading = false;
      });
      throw error;
    }
  };

  @action loadProtocolTypeInfo = async (protocolTypeId: number) => {
    this.isLoading = true;
    try {
      const response = await agent.Protocols.getProtocolTypeInfo(protocolTypeId);
      // console.log(response);
      runInAction("load protocol data", () => {
        this.protocolTypeInfoRegistry.set(protocolTypeId, response);
        this.isLoading = false;
      });
    } catch (error) {
      runInAction("load protocol type info error", () => {
        console.log(error);
        this.isLoading = false;
      });
      throw error;
    }
  };


  @action saveProtocolTypeInfo = async (protocol: IProtocolTypeInfo) => {
    try {
      this.isLoading = true;
      //console.log(protocol);
      const response = await agent.Protocols.updateProtocolTypeInfo(protocol);
      toast.success(`Konfigurace protokolu byla uložena`);
      runInAction("update protocol data", () => {
        this.protocolTypeInfoRegistry.set(protocol.protocolTypeId, response);
        this.isLoading = false;
      });
      return response;
    } catch (error) {
      console.log(error);
      toast.error("Při ukládání konfigurace protokolu nastala chyba!");
      this.isLoading = false;
      throw error;
    }
  };

  @action loadProtocolAreaSettings = async (protocolTypeId: number) => {
    this.isLoading = true;
    try {
      this.protocolAreaRegistry.clear();
      const response = await agent.Protocols.getProtocolAreas(protocolTypeId);

      runInAction("load protocol data", () => {
        this.protocolAreaRegistry.set(protocolTypeId, response);
        this.isLoading = false;
      });
    } catch (error) {
      runInAction("load protocol area error", () => {
        console.log(error);
        this.isLoading = false;
      });
      throw error;
    }
  };

  @action saveProtocolAreaSettings = async (protocol: IProtocolAreasSetting) => {
    try {
      this.isLoading = true;
      //console.log(protocol);
      const response = await agent.Protocols.updateProtocolAreas(protocol);
      toast.success(`Konfigurace protokolu byla uložena`);
      runInAction("update protocol data", () => {
        this.protocolAreaRegistry.set(protocol.protocolTypeId, response);
        this.isLoading = false;
      });
      return response;
    } catch (error) {
      console.log(error);
      toast.error("Při ukládání konfigurace protokolu nastala chyba!");
      this.isLoading = false;
      throw error;
    }
  };

  @action loadProtocolPartGroupSettings = async (protocolTypeId: number) => {
    this.isLoading = true;
    try {
      this.protocolPartGroupRegistry.clear();
      const response = await agent.Protocols.getProtocolPartGroups(
        protocolTypeId
      );
      // console.log(response);
      runInAction("load protocol data", () => {
        this.protocolPartGroupRegistry.set(protocolTypeId, response);
        this.isLoading = false;
      });
    } catch (error) {
      runInAction("load protocol area error", () => {
        console.log(error);
        this.isLoading = false;
      });
      throw error;
    }
  };

  @action saveProtocolPartGroupSettings = async (protocol: IProtocolPartGroupsSetting) => {
    try {
      this.isLoading = true;
      this.protocolPartGroupRegistry.clear();
      //console.log(protocol);
      const response = await agent.Protocols.updateProtocolPartGroups(protocol);
      toast.success(`Konfigurace protokolu byla uložena`);
      runInAction("update protocol data", () => {
        this.protocolPartGroupRegistry.set(protocol.protocolTypeId, response);
        this.isLoading = false;
      });
      return response;
    } catch (error) {
      console.log(error);
      toast.error("Při ukládání konfigurace protokolu nastala chyba!");
      this.isLoading = false;
      throw error;
    }
  };

  @action saveProtocolPartSetting = async (partSetting: IPartSettingDTO) => {
    try {
      this.isLoading = true;
      //console.log(protocol);
      const response = await agent.Protocols.updateProtocolPart(partSetting);
      this.isLoading = false;
      return response;
    } catch (error) {
      console.log(error);
      toast.error("Při ukládání dílu protokolu nastala chyba!");
      this.isLoading = false;
      throw error;
    }
  };

  @action loadProtocolEnums = async (protocolTypeId: number) => {
    this.isLoading = true;
    try {
      this.protocolAreaEnumsRegistry.clear();
      this.protocolGroupEnumsRegistry.clear();
      this.protocolPartEnumsRegistry.clear();
      const response = await agent.Protocols.getProtocolEnums(protocolTypeId);
      runInAction("load protocol enums", () => {
        this.protocolAreaEnumsRegistry.set(protocolTypeId, response.areaEnums);
        this.protocolGroupEnumsRegistry.set(protocolTypeId, response.groupEnums);
        this.protocolPartEnumsRegistry.set(protocolTypeId, response.partEnums);
        this.isLoading = false;
      });
    } catch (error) {
      runInAction("load protocol enums error", () => {
        console.log(error);
        this.isLoading = false;
      });
      throw error;
    }
  };

  @action copyProtocolRulesSetting = async (copyProtocolForm: IProtocolCopyRulesDTO) => {
    this.isLoading = true;
    try {

      const response = await agent.Protocols.copyProtocolRules(copyProtocolForm);
      runInAction("load protocol enums", () => {
        this.isLoading = false;
      });
    } catch (error) {
      runInAction("load protocol enums error", () => {
        console.log(error);
        this.isLoading = false;
      });
      throw error;
    }
  };

  @action getProtocolRuleConditionWithPrRelations = async (prNumbersString: string, carModelId?: number, carBodyTypeId?: number) : Promise<IProtocolRuleConditionSettingDTO> => {
          try {
            this.isLoading = true;
            const response = await agent.Protocols.getProtocolRuleConditionWithPrRelations(prNumbersString, carModelId, carBodyTypeId);
            runInAction(() => {
              this.isLoading = false;
            });
            return response;
          } catch (error) {
            console.log(error);
            toast.warn("Nastal problém při načtení PR podmínky");
            this.isLoading = false;
            throw error;
          }
  };

  @action getProtocolRuleConditionPrRelation = async (prNumberCode: string) : Promise<IProtocolRuleConditionPrRelationSettingDTO> => {
    try {
      this.isLoading = true;
      const response = await agent.Protocols.getProtocolRuleConditionPrRelation(prNumberCode);
      runInAction(() => {
        this.isLoading = false;
      });
      return response;
    } catch (error) {
      console.log(error);
      toast.warn("Nastal problém při načtení PR vazby");
      this.isLoading = false;
      throw error;
    }
};
}
