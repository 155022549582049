import "./PlanningConditionsTab.scss";

import React, {useContext, Fragment, useEffect, useState} from "react";
import { Row, Col, Form, Table, Button, ButtonGroup, OverlayTrigger, Tooltip, Container, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPen, faTrash, faSave } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react-lite";
import { arrayMoveImmutable } from 'array-move';
import { IEnums } from "../../../app/models/enum";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { IPlanningConditionsDTO, IPlanningCondition, PlanningCondition, IExpertGroupSequence, IExpertGroupDependence } from "../../../app/models/planningCondition";
import PlanningConditionForm from "./PlanningConditionForm";
import {v4 as uuidv4} from 'uuid';
import ExpertGroupSortableList from "./ExpertGroupSortableList"
import AlertMessage from "../../alert/AlertMessage";
import Nestable, { Item } from 'react-nestable';
import 'react-nestable/dist/styles/index.css';

interface IProps {
  enums: IEnums | undefined;
}

const PlanningConditionsTab: React.FC<IProps> = ({ enums }) => {

  const rootStore = useContext(RootStoreContext);
  const { loadPlanningConditions, savePlanningConditions, isLoading } = rootStore.planningConditionsStore
  const { openModal, closeModal } = rootStore.modalStore;
  const [ errors, setErrors ] = useState([]);
  const [ showSubmitError, setShowSubmitError ] = useState(false);
  const [ conditions, setConditions ] = useState<IPlanningCondition[]>([]);
  const [ expertGroupAbsoluteSequence, setExpertGroupAbsoluteSequence ] = useState<IExpertGroupSequence[]>([]);
  const [ expertGroupDependenceSequence, setExpertGroupDependenceSequence ] = useState<IExpertGroupDependence[]>([]);
  
  useEffect(() => {

    loadPlanningConditions().then(result => {
      if(result && result.conditions)
      {
        setConditions(result.conditions.map((c) => {
          c.uid = uuidv4();
          return c;
        }) || []);
      }
      
      if(result && result.expertGroupsAbsoluteSequence ){
        setExpertGroupAbsoluteSequence(result.expertGroupsAbsoluteSequence.map((g, index) => {
          if(!g.absoluteOrder){
            g.absoluteOrder = index + 1;
          }
          return g;
        }) || []);
      }

      if(result && result.expertGroupsDependenceSequence){
        setExpertGroupDependenceSequence(result.expertGroupsDependenceSequence);
      }
    });
  }, [loadPlanningConditions, setConditions, setExpertGroupAbsoluteSequence, setExpertGroupDependenceSequence ]);

  const onSortEnd = (moveData: any) => {
    const newIndex = moveData["newIndex"];
    const oldIndex = moveData["oldIndex"]
    const expertGroupSequence = arrayMoveImmutable(expertGroupAbsoluteSequence, oldIndex, newIndex);
    expertGroupSequence.map((g, index) => {
      g.absoluteOrder = index + 1;
      return g;
    });
    setExpertGroupAbsoluteSequence(expertGroupSequence);
  };

  const handlePlanningCondition = (planningConditionForm: IPlanningCondition) => {
    setConditions([ ...conditions.filter((x) => x.uid !== planningConditionForm.uid), planningConditionForm]);
    closeModal();
  };

  const handleAddNewPlanningCondition = () => {
    var planningCondition: IPlanningCondition = new PlanningCondition();
    openModal(
      <PlanningConditionForm planningCondition={planningCondition} enums={enums} handlePlanningConditionForm={handlePlanningCondition} />,
      {
        preventClosing: true,
        title: "Přidat podmínku plánování",
        size: "xl"
      }
    );
  }

  const handleEditPlanningCondition = (uid: string) => {
    var condition = conditions.find((c) => c.uid == uid);
    if(condition){
      openModal(
        <PlanningConditionForm planningCondition={condition} enums={enums} handlePlanningConditionForm={handlePlanningCondition} />,
        {
          preventClosing: true,
          title: "Upravit podmínku plánování",
          size: "xl"
        }
      );
    }
  }

  const deleteCondition = (uid: string) => {
    var condition = conditions.find((c) => c.uid == uid);
    if(condition){
      var _conditions = conditions.filter((t) => t.uid !== uid);
      condition.isActive = false;
      _conditions.push(condition);
      setConditions(_conditions);
    }
  }

  const getExpertGroupDependenceArray = (items: Item[]) : IExpertGroupDependence[] => {
    if(!items || items.length === 0) return [];

    const seq: IExpertGroupDependence[] = items.map((i) => ({
            id: i.id,
            code: i.code,
            text: i.text,
            color: i.color,
            children: getExpertGroupDependenceArray(i.children)
          })) || [];

     return seq;
  }

  const SaveChanges = () => {

    const planningConditions: IPlanningConditionsDTO = {
      conditions: conditions,
      expertGroupsAbsoluteSequence: expertGroupAbsoluteSequence,
      expertGroupsDependenceSequence: expertGroupDependenceSequence
     };

     setErrors([]);
     setShowSubmitError(false);
     savePlanningConditions(planningConditions)
     .then(result => {
      if(result && result.conditions)
      {
        setConditions(result.conditions.map((c) => {
          c.uid = uuidv4();
          return c;
        }) || []);
      }
      if(result && result.expertGroupsAbsoluteSequence){
        setExpertGroupAbsoluteSequence(result.expertGroupsAbsoluteSequence);
      }
      if(result && result.expertGroupsDependenceSequence){
        setExpertGroupDependenceSequence(result.expertGroupsDependenceSequence);
      }
    })
     .catch((error: any) => {
       console.log(error);
       setErrors(
         error.response?.data?.errors &&
           Object.values(error.response?.data?.errors)
       );
       setShowSubmitError(true);
     });
  }

  const buttonGroup = (condition: IPlanningCondition) => (
    <ButtonGroup>
      <Button
        disabled={true}
        size="sm"
        variant={
          condition.emissionTest && condition.emissionTest === true ? "primary" : "outline-primary"
        }
      >
        {"ano"}
      </Button>
      <Button
        disabled={true}
        size="sm"
        variant={
          typeof condition.emissionTest === "boolean" && condition.emissionTest === false
            ? "primary" : "outline-primary"
        }
      >
        {"ne"}
      </Button>
    </ButtonGroup>
  );

  return (
    <div>

      <div className="pageheader">
        <div className="pageheader__left">
          <h2>Podmínky plánování zkoušek odborných skupin</h2>
        </div>
        <div className="pageheader__right">
          <Button
            variant="outline-primary"
            style={{
              marginRight: "1rem",
            }}
            type="button"
            onClick={() => SaveChanges()}
          >
            <FontAwesomeIcon icon={faSave} /> Uložit
          </Button>
        </div>
      </div>

      <AlertMessage
        type="danger"
        heading="Nastala chyba při ukládání"
        show={showSubmitError}
        setShow={setShowSubmitError}
        messageList={errors}
      />

      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header className="flex-row flex-space-between">
              <div>Seznam podmínek</div>
              <div>
                <OverlayTrigger overlay={<Tooltip id={`tooltip-addbutton`}>Přidat pravidlo</Tooltip>}>
                  <Button
                    style={{ marginRight: "1rem" }}
                    variant="primary"
                    onClick={() => handleAddNewPlanningCondition()}
                    className="footer__btn-add"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </OverlayTrigger>
              </div>
            </Card.Header>
            <Card.Body>
              <Table>
                <thead>
                  <tr>
                    <th>Typ vozu</th>
                    <th>Podurčení zkoušky</th>
                    <th>Typ zkoušky</th>
                    <th>Odběratel</th>
                    <th>Model</th>
                    <th>Karoserie</th>
                    <th>Výbava</th>
                    <th>Motorizace</th>
                    <th>Emisní vůz</th>
                    <th>Plánování</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {conditions.filter( x => x.isActive).map((condition, index) => (
                    <tr key={condition.uid} className="condition_row">
                      <td>{condition.testDestinationName}</td>
                      <td>{condition.testPredestinationName}</td>
                      <td>{condition.testTypeName}</td>
                      <td>{condition.customerCode}</td>
                      <td>{condition.carModelName}</td>
                      <td>{condition.carBodyTypeName}</td>
                      <td>{condition.carEquipmentName}</td>
                      <td>{condition.carEngineName}</td>
                      <td>{condition.emissionTest && buttonGroup(condition)}</td>
                      <td>{condition.planningTypeName}</td>
                      <td>
                      <Button
                          size="sm"
                          style={{ width: "3em" }}
                          variant="link"
                          title="Komentáře k závadám"
                          type="button"
                          onClick={() => handleEditPlanningCondition(condition.uid)}
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </Button>
                        <Button
                          variant="link"
                          size="sm"
                          onClick={() => deleteCondition(condition.uid)}
                          title="Smazat podmínku"
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Card>
            <Card.Header className="flex-row flex-space-between">
                <div>Absolutní pořadí odborných skupin</div> 
              </Card.Header>
              <Card.Body>
                <ExpertGroupSortableList expertGroupSequence={expertGroupAbsoluteSequence}  onSortEnd={onSortEnd} />
              </Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
          <Card>
            <Card.Header className="flex-row flex-space-between">
              <div>Závislost odborných skupin</div>
            </Card.Header>
            <Card.Body>
              <Nestable
                items={expertGroupDependenceSequence}
                collapsed={false}
                renderItem={(data) => {
                  return (
                    <div className="sequence-list-row nestable-style">
                      <div className={`circle circle--${data.item.color}`}></div>
                      <div className="sequence-list-item" style={{ marginLeft: "20px", width: "35px" }}>{data.item.code}</div>
                      <div className="sequence-list-item">-</div>
                      <div className="sequence-list-item">{data.item.text}</div>
                    </div>
                  )}}
                onChange={(treeData) => {
                    const seq = getExpertGroupDependenceArray(treeData.items);
                    setExpertGroupDependenceSequence(seq);
                    return;
                  }}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </div>
  );
};

export default observer(PlanningConditionsTab);
