import React, { Fragment } from "react";
import { Table } from "react-bootstrap";
import { Field, useForm } from "react-final-form";
import { IEngineUnitAndChassisProtocol  } from "../../../../app/models/protocol";
import CheckboxInput from "../../../../app/common/form/CheckboxInput";
import SelectInput from "../../../../app/common/form/SelectInput";
import ExecutedTestInArea from "./ExecutedTestInArea";

interface IProps {
  protocol: IEngineUnitAndChassisProtocol;
  isEdit: boolean;
  isDisabled: boolean;
}

const ProtocolOverviewArea: React.FC<IProps> = ({ protocol, isEdit, isDisabled}) => {

  //const form = useForm<IEngineUnitAndChassisProtocol>();
  
  return (
    <Fragment>
      <Table className="protocol-table">
        <thead>
          <tr>
            <th colSpan={10} className={`headingrow`}>
              <h2>{protocol.protocolOverviewArea?.name}</h2>
            </th>
            <th colSpan={2} className={`headingrow`}>
              <Field
                name={`protocolOverviewArea.notPerform`}
                title="Neprovedeno"
                options={[
                    { key: 1, value: "ano" },
                    { key: 0, value: " ne " },
                ]}
                format={(v) => (v ? 1 : 0)}
                parse={(v) => (v ? true : false)}
                type={"checkbox"}
                initialValue={protocol.protocolOverviewArea?.notPerform || false}
                component={CheckboxInput}
                disabled={!isEdit}
                labelWidth={8}
                valueWidth={4}
              />
            </th>
          </tr>
        </thead>
      </Table>

      <Table className={`protocol-table`}>
        <thead>
          <tr className={`${ isDisabled ? "disabled" : ""}`}>
              <th className="labelrow" colSpan={6}>Seznam zkoušek</th>
              <th className="labelrow" colSpan={2} style={{textAlign: "center"}}>Provedeno</th>
              <th className="labelrow" colSpan={2} style={{textAlign: "center"}}>Neprovedeno</th>
              <th className="labelrow" colSpan={2} style={{textAlign: "left"}}>Hodnocení</th>
            </tr>
          </thead>
          <tbody>
            {protocol.areas?.map((area, index) => (
              <ExecutedTestInArea area={area} areaIndex={index} isEdit={isEdit} isDisabled={isDisabled} />
            ))}
            <tr className={`${ isDisabled ? "disabled" : ""}`}>
                <td colSpan={6}>Celkové hodnocení</td>
                <td colSpan={2}></td>
                <td colSpan={2}></td>
                <td style={{textAlign: "center"}}>
                  <Field
                    name={`protocolOverviewArea.signDefect`}
                    options={[
                      { key: null, value: "" },
                      { key: 1, value: "1 - A1" },
                      { key: 2, value: "2 - A" },
                      { key: 3, value: "3 - A" },
                      { key: 4, value: "4 - B1" },
                      { key: 5, value: "5 - B" },
                      { key: 6, value: "6 - C1" },
                      { key: 7, value: "7 - C" },
                      { key: 8, value: "8 - C" },
                      { key: 9, value: "9 - C" },
                      { key: 10, value: "10 - C" },
                    ]}
                    component={SelectInput}
                    disabled={!isEdit || isDisabled}
                  />
                </td>
                <td></td>
            </tr>
          </tbody>
        </Table>
    </Fragment>
  );
};

export default ProtocolOverviewArea;