export const getFileExtension = (fileName: string) : string => {
    var fileNamePatrs = fileName.split(".");
    return  `${fileNamePatrs[fileNamePatrs.length - 1]}`;
}

export const getFileSize = (length: number) : string => {
    let size: string = "";
    if(length < 1024)
        size = `${length} B`;
    else if(length > 1024 && length < 1024 * 1024)
        size = `${ (length / 1024).toFixed(3) } KB`;
    else if(length > 1024 * 1024)
        size = `${ (length / (1024 * 1024)).toFixed(3) } MB`;
    return size;
}