import React, { Fragment } from "react";
import { Field, useForm, useFormState } from "react-final-form";
import { IProtocolArea, IEngineUnitAndChassisProtocol } from "../../../../app/models/protocol";
import { FormApi } from "final-form";
import CheckboxGroupInput from "../../../../app/common/form/CheckboxGroupInput";
import SelectInput from "../../../../app/common/form/SelectInput";

interface IProps {
  area?: IProtocolArea;
  areaIndex: number;
  isEdit: boolean;
  isDisabled: boolean;
}

const ExecutedTestInArea: React.FC<IProps> = ({ area, areaIndex, isEdit, isDisabled}) => {

  const form = useForm<IEngineUnitAndChassisProtocol>();
  const formState = useFormState<IEngineUnitAndChassisProtocol>();
  let values = useFormState<IEngineUnitAndChassisProtocol>().values;
  let isDisabledArea = values.areas !== undefined ? values.areas[areaIndex].notPerform : false;
  
  if(!area) return <></>

  return (
    <Fragment>
      <tr className={`${ isDisabled || isDisabledArea ? "disabled" : ""}`}>
              <td colSpan={6}>{area?.name}</td>
              <td colSpan={2} style={{textAlign: "center"}}>
                <Field
                  name={`areas[${areaIndex}].perform`}
                  checked={area.perform}
                  type="checkbox"
                  options={[
                    { key: 1, value: "ano" },
                    { key: 0, value: " ne " },
                  ]}
                  format={(v) => (v ? 1 : 0)}
                  parse={(v) => (v ? true : false)}
                  inputOnChange={(e: any) => {
                    form.mutators.handleChangePerformAreaOrGroup(e);
                  }}
                  component={CheckboxGroupInput}
                  disabled={!isEdit || isDisabled}
                />
              </td>
              <td colSpan={2} style={{textAlign: "center"}}>
                <Field
                  name={`areas[${areaIndex}].notPerform`}
                  checked={area.notPerform}
                  type="checkbox"
                  options={[
                    { key: 1, value: "ano" },
                    { key: 0, value: " ne " },
                  ]}
                  format={(v) => (v ? 1 : 0)}
                  parse={(v) => (v ? true : false)}
                  inputOnChange={(e: any) => {
                    form.mutators.handleChangePerformAreaOrGroup(e);
                  }}
                  component={CheckboxGroupInput}
                  disabled={!isEdit || isDisabled}
                />
              </td>
              <td style={{textAlign: "center"}}>
                <Field
                  name={`areas[${areaIndex}].signDefect`}
                  options={[
                    { key: null, value: "" },
                    { key: 1, value: "1 - A1" },
                    { key: 2, value: "2 - A" },
                    { key: 3, value: "3 - A" },
                    { key: 4, value: "4 - B1" },
                    { key: 5, value: "5 - B" },
                    { key: 6, value: "6 - C1" },
                    { key: 7, value: "7 - C" },
                    { key: 8, value: "8 - C" },
                    { key: 9, value: "9 - C" },
                    { key: 10, value: "10 - C" },
                  ]}
                  component={SelectInput}
                  disabled={!isEdit || isDisabled || isDisabledArea}
                />
              </td>
              <td></td>
            </tr>
            {area?.protocolPartGroups?.map((group, groupIndex) => {
              let isDisableGroup: boolean = false;
              if(formState.values.areas !== undefined && formState.values.areas[areaIndex] !== undefined &&
                formState.values.areas[areaIndex].protocolPartGroups !== undefined && formState.values.areas[areaIndex].protocolPartGroups[groupIndex] !== undefined)
              {
                  isDisableGroup = formState.values.areas[areaIndex].protocolPartGroups[groupIndex].notPerform || false;
              }
              return (
                <tr key={`executing_areas[${areaIndex}].protocolPartGroups[${groupIndex}]`} className={`${ isDisabled || isDisabledArea || isDisableGroup ? "disabled" : ""}`}>
                  <td></td>
                  <td colSpan={5}>{group?.name}</td>
                  <td colSpan={2} style={{textAlign: "center"}}>
                    <Field
                      name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].perform`}
                      checked={group.perform !== undefined && group.perform}
                      type="checkbox"
                      options={[
                        { key: 1, value: "ano" },
                        { key: 0, value: " ne " },
                      ]}
                      format={(v) => (v ? 1 : 0)}
                      parse={(v) => (v ? true : false)}
                      inputOnChange={(e: any) => {
                        form.mutators.handleChangePerformAreaOrGroup(e);
                      }}
                      component={CheckboxGroupInput}
                      disabled={!isEdit || isDisabled || isDisabledArea}
                    />
                  </td>
                  <td colSpan={2} style={{textAlign: "center"}}>
                    <Field
                      name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].notPerform`}
                      checked={group.notPerform !== undefined && !!group.notPerform}
                      type="checkbox"
                      options={[
                        { key: 1, value: "ano" },
                        { key: 0, value: " ne " },
                      ]}
                      format={(v) => (v ? 1 : 0)}
                      parse={(v) => (v ? true : false)}
                      inputOnChange={(e: any) => {
                        form.mutators.handleChangePerformAreaOrGroup(e);
                      }}
                      component={CheckboxGroupInput}
                      disabled={!isEdit || isDisabled || isDisabledArea}
                    />
                  </td>
                  <td style={{textAlign: "center"}}></td>
                  <td></td>
                </tr>
                )
              })}
    </Fragment>
  );
};

export default ExecutedTestInArea;