import React, { useContext, useEffect, useState } from "react";
import { Field, useFormState } from "react-final-form";
import TextInput from "../../../app/common/form/TextInput";
import { Row, Table, Collapse, Button } from "react-bootstrap";
import { IRuleSettingDTO, IProtocolRuleConditionSettingDTO, IProtocolRuleConditionPrRelationSettingDTO } from "../../../app/models/protocol";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../app/stores/rootStore";
import SwitchInput from "../../../app/common/form/SwitchInput";

interface IProps {
    condition: IProtocolRuleConditionSettingDTO,
    conditionIndex: number,
    openConditionUid: string,
    handleAddPrRaletionToCondition: (conditionIndex: number) => void;
}

const ProtocolRuleConditionPrRalations: React.FC<IProps> = ({condition, conditionIndex, openConditionUid, handleAddPrRaletionToCondition}) => {
    return (
        <Collapse in={openConditionUid === condition.uid}>
            <Row key={`conditionRelationRow_${condition.protocolRuleConditionId}`}>
                <Table>
                    <thead>
                        <tr>
                            <th>PR Rodiny</th>
                            <th>Název Rodina</th>
                            <th>PR číslo</th>
                            <th>Název PR čísla</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {condition.prRelations.map((relation, relationIndex) => (
                            <tr key={`PrRelationRow_${relation.protocolRuleConditionPrRelationId}`}> 
                                <td>{relation.prFamilyCode}</td>
                                <td>{relation.prFamilyName}</td>
                                <td>{relation.prNumberCode}</td>
                                <td>{relation.prNumberName}</td>
                                <td style={{textAlign: "right"}} >
                                <Field
                                    name={`protocolRuleConditions[${conditionIndex}].prRelations[${relationIndex}].isActive`}
                                    options={[
                                    { key: 1, value: "ano" },
                                    { key: 0, value: " ne " },
                                    ]}
                                    format={(v) => (v ? 1 : 0)}
                                    parse={(v) => (v ? true : false)}
                                    component={SwitchInput}
                                />
                                </td>
                            </tr>    
                        ))}
                        <tr>
                        <td></td>
                        <td></td>
                        <td>
                            <Field
                                name={`protocolRuleConditions[${conditionIndex}].addedPrNumber`}
                                component={TextInput}
                                size={"sm"}
                            />
                        </td>
                        <td></td>
                        <td style={{textAlign: "right"}}>
                            <Button
                                type="button"
                                onClick={() => handleAddPrRaletionToCondition(conditionIndex)}
                                className="btn-modal"
                            >
                            Doplnit PR číslo
                        </Button>
                        </td>
                        </tr>
                    </tbody>
                </Table>
            </Row>
        </Collapse>
                    
    );
}
export default observer(ProtocolRuleConditionPrRalations);