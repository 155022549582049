import "./PlanningConditionsTab.scss";
import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { IExpertGroupSequence } from "../../../app/models/planningCondition";

interface IProps {
    group: IExpertGroupSequence,
    index: number
}

const ExpertGroupSortableItem: React.FC<IProps> = ({group, index}) => {
  return <li key={index} className="sequence-list-li">
            <div className="sequence-list-row">
                <div className="sequence-list-item" style={{ marginLeft: "20px", width: "35px" }}>{group.absoluteOrder}</div>
                <div className={`circle circle--${group.color}`}></div>
                <div className="sequence-list-item" style={{ marginLeft: "20px", width: "35px" }}>{group.code}</div>
                <div className="sequence-list-item">-</div>
                <div className="sequence-list-item">{group.name}</div>
            </div>
         </li>;
};

export default SortableElement(ExpertGroupSortableItem);