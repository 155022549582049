import React, { Fragment, useContext, useEffect, useState } from "react";
import  MultiFilesUploaderPanel from "../../filesUpload/MultiFilesUploaderPanel";
import { Col, Row } from "react-bootstrap";
import { IAttachment } from "../../../app/models/attachment";
import AttachmentsList from "../../attachments/AttachmentsList";

interface IProps {
  carId: number;
  attachments: IAttachment[] | undefined;
  deleteAttachments(attachmentId: number) : void;
  addAttachment: (attachments: IAttachment) => void;
}

const CarAttachmentsTab: React.FC<IProps> = ({ carId, attachments, deleteAttachments, addAttachment }) => {

  const existsAttachments = attachments && attachments?.length > 0;
  const uploadPanelSize = existsAttachments ? 6 : 12;
  
  return (
    <Fragment>
        <Row>
          <AttachmentsList attachments={attachments} colSize={uploadPanelSize} deleteAttachments={deleteAttachments} />

          <Col md={uploadPanelSize}>
            <MultiFilesUploaderPanel carId={carId} carTestId={undefined} carProtocolId={undefined} carAnalysisId={undefined} carDefectId={undefined} addAttachment={addAttachment} />
          </Col>
        </Row>
    </Fragment>
  );
};

export default CarAttachmentsTab;
