import "./protocolRuleDataExtensionTab.scss";
import React, {Fragment, useContext, useState} from "react";
import {Field, Form as FinalForm} from "react-final-form";
import {Button, Form, OverlayTrigger, Table, Tooltip} from "react-bootstrap";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {
  AreaEnumDTO,
  GroupEnumDTO,
  PartEnumDTO,
  IProtocolRuleDataExtSpecSettingDTO,
  IProtocolRuleConditionDataExtSpecDTO,
} from "../../../app/models/protocol";
import {faPen, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Loader from "../../loader/Loader";
import {observer} from "mobx-react-lite";
import ProtocolRuleDataExtensionSpecForm from "./ProtocolRuleDataExtensionSpecForm"

import { useProtocolRuleDataExtensionSettingsQuery } from "../../../app/api/client";


interface IProps {
  protocolTypeId?: number | undefined;
  areaEnums?: AreaEnumDTO[] | undefined;
  groupEnums?:  GroupEnumDTO[] | undefined;
  partEnums?:  PartEnumDTO[] | undefined;
}

const ProtocolRuleDataExtensionTab: React.FC<IProps> = ({
  protocolTypeId,
  areaEnums,
  groupEnums,
  partEnums,
}) => {
  
  const rootStore = useContext(RootStoreContext);
  const { openModal } = rootStore.modalStore;

  const {data: protocolRuleDataExtensionsSetting, isFetching: isLoading} = useProtocolRuleDataExtensionSettingsQuery(protocolTypeId || 0);

  if (isLoading) return <Loader></Loader>

    const handleAddNewRuleDataExtension = () => {
      let dataExtensionSpec: IProtocolRuleConditionDataExtSpecDTO | undefined = {
        protocolRuleConditionDataExtSpecId: undefined,
        protocolTypeId: protocolTypeId || 0,
        protocolAreaId: undefined,
        protocolAreaName: "",
        protocolPartGroupId: undefined,
        protocolPartGroupName: "",
        protocolPartId: undefined,
        protocolPartName: "",
        name: "",
        description: "",
        attributeName: "",
        attributeType: ""
      }
      if(dataExtensionSpec){
        openModal(
          <ProtocolRuleDataExtensionSpecForm protocolRuledataExtensionSpec={dataExtensionSpec} areasEnum={areaEnums} groupsEnum={groupEnums} partsEnum={partEnums} />,
          {
              preventClosing: true,
              title: "Nové specifikace rozšiřujících dat pravidel protokolu",
              size: "xl",
          })
      }
    }
  
    const handleOpenModalFormRuleDataExtension = (protocolRuleDataExtensionSpecId: number) => {
      let dataExtensionSpec: IProtocolRuleConditionDataExtSpecDTO | undefined = protocolRuleDataExtensionsSetting?.dataExtensionsSpec?.find(x => x.protocolRuleConditionDataExtSpecId === protocolRuleDataExtensionSpecId);
      if(dataExtensionSpec){
        openModal(
          <ProtocolRuleDataExtensionSpecForm protocolRuledataExtensionSpec={dataExtensionSpec} areasEnum={areaEnums} groupsEnum={groupEnums} partsEnum={partEnums} />,
          {
              preventClosing: true,
              title: "Úprava specifikace rozšiřujících dat pravidel protokolu",
              size: "xl",
          })
      }
    }
  
  const handleSubmitForm = (_: IProtocolRuleDataExtSpecSettingDTO) => {
  };
 
  return (
    <FinalForm
        onSubmit={handleSubmitForm}
        initialValues={protocolRuleDataExtensionsSetting}
        render={({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div className="pageheader">
              <div className="pageheader__left">
                <h3>Konfigurace atributů rozšiřujících dat pravidel protokolu</h3>
              </div>
            <div className="pageheader__right">

            </div>
      </div>
      <div className="protocol-table" id="protocol-rule-settings">
        {protocolRuleDataExtensionsSetting && protocolRuleDataExtensionsSetting.dataExtensionsSpec && protocolRuleDataExtensionsSetting.dataExtensionsSpec.length > 0 && (
          <Table>
            <thead>
              <tr>
                <th colSpan={2}>Název oblasti</th>
                <th colSpan={2}>Název skupiny</th>
                <th colSpan={2}>Název dílu</th>
                <th colSpan={2}>Název rozšiřujících dat</th>
                <th>Název atributu</th>
                <th>Typ</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {protocolRuleDataExtensionsSetting.dataExtensionsSpec
                  .slice()
                  .map((dataExtSpec, index) => {
                    return (
                        <Fragment>
                          <tr key={`dataExtSpec_${dataExtSpec.protocolRuleConditionDataExtSpecId}`}>
                            <td colSpan={2}>
                              {dataExtSpec.protocolAreaName}
                            </td>
                            <td colSpan={2}>
                              {dataExtSpec.protocolPartGroupName}
                            </td>
                            <td colSpan={2}>
                              <div>{dataExtSpec.protocolPartName}</div>
                            </td>
                            <td colSpan={2}>
                              {dataExtSpec.name}
                            </td>
                            <td>
                              {dataExtSpec.attributeName}
                            </td>
                            <td colSpan={2} style={{ whiteSpace: "nowrap" }}>
                              {dataExtSpec.attributeType}
                            </td>
                            <td style={{paddingBottom: "0.75rem"}}>
                              <Button
                                  size="sm"
                                  style={{width: "3em"}}
                                  variant="primary"
                                  title="Editace specifikace rozšiřujících dat"
                                  onClick={() => handleOpenModalFormRuleDataExtension(dataExtSpec.protocolRuleConditionDataExtSpecId || 0)}
                              >
                                <FontAwesomeIcon icon={faPen}/>
                              </Button>
                            </td>
                          </tr>
                        </Fragment>
                      )})}
                  </tbody>
                </Table>
            )}
      </div>
      <div className="pageheader">
              <div className="pageheader__left">
              </div>
              <div className="pageheader__right">
                <Fragment>
                  <OverlayTrigger overlay={
                    <Tooltip id={`tooltip-addbutton`}>Přidat pravidlo</Tooltip>
                    }
                    >
                    <Button
                      style={{ marginRight: "1rem" }}
                      variant="primary"
                      onClick={() => handleAddNewRuleDataExtension()}
                      className="footer__btn-add"
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </OverlayTrigger>
                </Fragment>
              </div>
            </div>
      </Form>
      )}
    />
  );
};

export default observer(ProtocolRuleDataExtensionTab);
