import { ITestDefinition } from "./testDefinition";
import { ICar, ICarProtocolListItem } from "./car";
import { NonRelativeModuleNameResolutionCache } from "typescript";
import { IAttachment } from "./attachment";

export interface ICarTest {
  carTestId: number;
  carId: number;
  carVIN: string;
  carLogisticNum: number;
  carTestTypeName: string;
  carTestDestinationName: string;
  carTestPredestinationName: string;
  testDefinitionId?: number;
  description: string;
  expertGroupId: number;
  expertGroupCode: string;
  expertGroupName: string;
  expertGroupColor: string;
  testStateId: number;
  testStateName: string;
  length: number;
  workerCount: number;
  assignedWorkers?: IWorker[];
  extraWorkers?: IWorker[];
  plannedWorkers?: number[];
  expertGroupWorkers: IWorker[];
  shortened: boolean;
  testCharacteristicsId: number;
  testCharacteristics: string;
  startAt?: Date;
  endAt?: Date;
  note: string;
  isDeleted: boolean;
  confirmed?: boolean;
  carTestConflicts: ICarTestConflict[];
  submitWarnings?: string[];
  testStateInDelay: boolean;
  placeId:number;
  placeCode: string;
  protocols: ICarProtocolListItem[];
  termTypeId: number;
}
export interface ICarTestDetail {
  carTest: ICarTest;
  car: ICar;
  attachments: IAttachment[];
}

export interface IWorker {
  userId: number;
  userFullName: string;
  shortcut:string;
  isActive:boolean;
  performTests:boolean;
  isAvailableToTest:boolean;
  color:string;
}

export interface ICarTestConflict {
  carTestConflictId: number;
  carId: number;
  carTestId?: number;
  carAnalysisId?: number;
  carAnalysisTermId?: number;
  conflictId: number;
  conflictCode: string;
  conflictName: string;
  message: string;
  sourceId?: number;
  inConflict: boolean;
}

export interface ICarTestCarDetailForm {
  carTestId?: number;
  testDefinitionId?: number;
  testStateId: number;
  expertGroupId: number;
  length: number;
  testCharacteristicsId: number;
  description?: string;
}

export interface ICarTestAutoAssign {
  carTestId?: number;
  testDefinitionId: number;
  testStateId: number;
  expertGroupId: number;
  expertGroupName: string;
  startAt?: Date;
  endAt?: Date;
  length: number;
  testCharacteristicsId: number;
  description?: string;
}

export interface ICarTestManuallyAdded {
  testStateId: number;
  expertGroupId: number;
  length: number;
  description: string;
  rownumber: number;
}

export class CarTestAutoAssignForm implements ICarTestAutoAssign {
  carTestId?: number = undefined;
  testDefinitionId: number = 0;
  expertGroupId: number = 0;
  expertGroupName = "";
  testStateId: number = 0;
  testCharacteristicsId: number = 0;
  shortened: boolean = false;
  length: number = 0;
  description?: string = undefined;
  startAt = undefined;
  endAt = undefined;

  constructor(init: ITestDefinition) {
    this.testDefinitionId = init.testDefinitionId;
    this.expertGroupId = init.expertGroupId;
    this.expertGroupName = init.expertGroupName;
    this.testStateId = 1;
    this.testCharacteristicsId = init.testCharacteristicsId;
    this.shortened = false;
    this.length = init.fullLength;
  }
}

export interface ICarTestFormDTO {
  carTest: ICarTestForm;
  isConfirmedSave: boolean;
}

export interface ICarTestForm {
  carTestId: number;
  testDefinitionId?: number;
  testStateId: number;
  expertGroupId: number;
  length: number;
  assignedWorkers: number[];
  extraWorkers: number[];
  
  shortened: boolean;
  testCharacteristicsId: number; // hodnota z ciselniku – staticka/dynamicka
  startAt?: Date;
  note: string;
  description: string;
}

export const convertICarTestToICarTestForm = (
  carTest: ICarTest
): ICarTestForm => {
  const carTestForm: ICarTestForm = (({
    carTestId,
    testDefinitionId,
    testStateId,
    expertGroupId,
    assignedWorkers,
    extraWorkers,
    length,
    shortened,
    testCharacteristicsId,
    description,
    startAt,
    note,
  }) => ({
    carTestId,
    testDefinitionId,
    testStateId,
    expertGroupId,
    assignedWorkers: assignedWorkers?.map((aw) => aw.userId) || [],
    extraWorkers: extraWorkers?.map((ew) => ew.userId) || [],
    length,
    shortened,
    testCharacteristicsId: testCharacteristicsId,
    description,
    startAt,
    note,
  }))(carTest);
  return carTestForm;
};

export const convertICarTestAutoAssignToICarTestCarDetailForm = (
  carTest: ICarTestAutoAssign
): ICarTestCarDetailForm => {
  const carTestForm: ICarTestCarDetailForm = (({
    carTestId,
    testDefinitionId,
    testStateId,
    expertGroupId,
    length,
    testCharacteristicsId,
    description,
  }) => ({
    carTestId,
    testDefinitionId,
    testStateId,
    expertGroupId,
    length,
    testCharacteristicsId,
    description,
  }))(carTest);
  return carTestForm;
};

export const convertICarTestToICarTestCarDetailForm = (
  carTest: ICarTest
): ICarTestCarDetailForm => {
  const carTestForm: ICarTestCarDetailForm = (({
    carTestId,
    testDefinitionId,
    testStateId,
    expertGroupId,
    length,
    testCharacteristicsId,
    description,
  }) => ({
    carTestId,
    testDefinitionId,
    testStateId,
    expertGroupId,
    length,
    testCharacteristicsId,
    description,
  }))(carTest);
  return carTestForm;
};

export const convertICarTestManuallyAddedToICarTestCarDetailForm = (
  carTest: ICarTestManuallyAdded
): ICarTestCarDetailForm => {
  const carTestForm: ICarTestCarDetailForm = (({
    testStateId,
    expertGroupId,
    length,
    description,
  }) => ({
    testStateId,
    expertGroupId,
    length,
    testCharacteristicsId: 1,
    description,
    testDefinitionId: undefined,
    carTestId: undefined,
  }))(carTest);
  return carTestForm;
};
