import "./RidingNoisesProtocol.scss";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {Field, Form as FinalForm} from "react-final-form";
import {Link, RouteComponentProps} from "react-router-dom";
import {RootStoreContext} from "../../../../app/stores/rootStore";
import {observer} from "mobx-react-lite";
import {IRidingNoisesProtocol,} from "../../../../app/models/protocol";
import {Button, Col, Collapse, Form, Row, Table} from "react-bootstrap";
import EditSaveResetButtons from "../../../ButtonGroups/EditSaveResetButtons";
import logo from "../../../../assets/images/Skoda_Wordmark_RGB_Emerald_Green.svg";
import TextAreaInput from "../../../../app/common/form/TextAreaInput";

import Can from "../../../../authorization/Can";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint, faFileExcel, faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import DefectsArea from "../protocolParts/DefectsArea";
import ProtocolHeaderCarInfo from "../../partialProtocols/protocolParts/ProtocolHeaderCarInfo";
import {FormApi} from "final-form";
import {
    useLoadEnumsQuery,
    useProtocolCodeTableValuesQuery,
    useRidingNoisesProtocolMutation,
    useRidingNoisesProtocolQuery,
} from "../../../../app/api/client";
import {toast} from "react-toastify";
import Loader from "../../../loader/Loader";
import RidingNoisesExecutedTestsArea from "./RidingNoisesExecutedTestsArea";
import MileageArea from "./MileageArea";
import DefectsCount from "../protocolParts/DefectsCount";
import {defectMutator} from "../protocolParts/DefectMutator";
import AlertMessage from "../../../alert/AlertMessage";
import agent from "../../../../app/api/agent";
import { IAttachment } from "../../../../app/models/attachment";
import AttachmentsList from "../../../attachments/AttachmentsList";
import MultiFilesUploaderPanel from "../../../filesUpload/MultiFilesUploaderPanel";

interface PartialProtocolParams {
    protocolTypeId: string;
    carId: string;
    carTestId: string;
    carProtocolId: string;
}

const RidingNoisesProtocol: React.FC<RouteComponentProps<PartialProtocolParams>> = ({
                                                                                        match,
                                                                                        history,
                                                                                    }) => {
    const rootStore = useContext(RootStoreContext);
    const {data: enums, isFetching: loadingEnums} = useLoadEnumsQuery();
    const {user} = rootStore.authStore;

    const [edit, setEdit] = useState(false);

    const [errors, setErrors] = useState([]);
    const [showSubmitError, setShowSubmitError] = useState(false);

    const [attachments, setAttachments ] = useState<IAttachment[] | undefined>(undefined);
    const [attachmentsCollapsed, setAttachmentsCollapsed] = useState(false);

    const protocolTypeId = Number(match.params.protocolTypeId);
    const carId = Number(match.params.carId);
    const carTestId = Number(match.params.carTestId);
    const carProtocolId = Number(match.params.carProtocolId);
    const existsCarProtocolId = match.params.carProtocolId && match.params.carProtocolId !== "null";

    const {
        data: ridingNoisesProtocol,
        isFetching: isLoading
    } = useRidingNoisesProtocolQuery(carId, carTestId, protocolTypeId, carProtocolId);
    const protocolCodeTableId = ridingNoisesProtocol?.executedTestArea?.partRules?.[0].protocolCodeTableId;
    const {data: codeTableValues} = useProtocolCodeTableValuesQuery(protocolTypeId, carTestId, protocolCodeTableId);
    const {mutateAsync} = useRidingNoisesProtocolMutation();

    useEffect(() => {
    if(ridingNoisesProtocol)
    {
        setAttachments(ridingNoisesProtocol.attachments)
        if(ridingNoisesProtocol.attachments && ridingNoisesProtocol.attachments.length > 0) setAttachmentsCollapsed(true);
    }
    }, [ridingNoisesProtocol])

    const handleFinalFormSubmit = async (protocol: IRidingNoisesProtocol) => {

        setErrors([]);
        setShowSubmitError(false);

        mutateAsync(protocol)
        .then((response) => {
            setEdit(false);
            toast.success(`Protokol byl uložen`);
            if (!match.params.carProtocolId || match.params.carProtocolId == 'null') {
                history.push(
                    `/RidingNoisesProtocol/${response.protocolTypeId}/${response.carId}/${response.carTestId}/null/${response.carProtocolId}`
                );
            }
        })
        .catch((error) => {
            setErrors(Object.values(error.response?.data?.errors));
            setShowSubmitError(true);
            toast.error(`Vyskytla se chyba při uložení protokolu`);
        });

    };

    // Reset all form values to initial values
    const handleReset = (form: FormApi) => {
        form.reset();
        setEdit(false);
    };

    const handleGetDocument = async () => {

        try {
          const protocolTypeId = Number(match.params.protocolTypeId);
          const carId = Number(match.params.carId);
          const carTestId = Number(match.params.carTestId);
          const carProtocolId = Number(match.params.carProtocolId);
    
          var response = await agent.Protocols.getProtocolDocumentXlsx(carId, carTestId, protocolTypeId, carProtocolId);
          const href = URL.createObjectURL(response.data);
          console.log(response.headers);
    
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', 'file.xlsx'); //or any other extension
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        } catch (error) {
            console.error("Error downloading:", error);
        }
      };

    const existsAttachments = attachments && attachments?.length > 0;
    const uploadPanelSize = existsAttachments ? 6 : 12;
    
    const deleteAttachment = (attachmentId: number) => {
        if(attachments){
        setAttachments(attachments.filter((a) => a.attachmentId !== attachmentId));
        }
    }
    
    const addAttachment = (attachment: IAttachment) => {
        if(attachments)
        {
        setAttachments((prev) => [...prev!, attachment]);
        }
        else{
        const newArrayAttachments: IAttachment[] = [];
        newArrayAttachments.push(attachment);
        setAttachments(newArrayAttachments)
        }
    }; 

    if (isLoading || loadingEnums || !codeTableValues) {
        return <Loader/>
    }


    return (
        <div
            id="RidingNoisesProtocol"
            className="container"
            style={{marginBottom: "3rem"}}
        >
            <div className="printheader">
                <div className="printheader__left">
                    <div className="printheader--image">
                        <img src={logo} alt='logo'/>
                    </div>
                </div>
                <div className="printheader__right">
                    <div className="text--header">Zkouškový protokol</div>
                    <div className="text--text">
                        Pracovní skupina{" "}
                        <span className="text--expert-group">
              {ridingNoisesProtocol?.carTest.expertGroupName}
            </span>
                    </div>
                    <div className="text--text">
                        <span>{ridingNoisesProtocol?.placeCode}</span> {ridingNoisesProtocol?.placeDescription}
                    </div>
                </div>
            </div>

            <div className="printfooter">
                <Table>
                    <tbody>
                    <tr>
                        <td colSpan={11}>
                            {ridingNoisesProtocol?.carTest.placeCode}{" "}
                            {ridingNoisesProtocol?.car?.modelVdsCode || ""} / 1_01 &emsp; ŠKODA
                            AUTO a.s., Tř. Václava Klementa 869, 293 01 Mladá Boleslav II.,
                            Česká republika
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}>{ridingNoisesProtocol?.documentName}</td>
                        <td>{ridingNoisesProtocol?.registrationNumber}</td>
                        <td>{ridingNoisesProtocol?.storageMethod}</td>
                        <td>{ridingNoisesProtocol?.shreddingSign}</td>
                        <td colSpan={2}>{ridingNoisesProtocol?.shreddingTriggerEvent}</td>
                        <td>{ridingNoisesProtocol?.classification}</td>
                        <td>{ridingNoisesProtocol?.storageLocation}</td>
                    </tr>
                    </tbody>
                </Table>
            </div>

            <table className="table-page-content">
                <thead>
                <tr>
                    <td>
                        {/* <!--placeholder for the fixed-position header--> */}
                        <div className="page-header-space"></div>
                    </td>
                </tr>
                </thead>

                <tbody>
                <tr>
                    <td>
                        <div>
                            <FinalForm<IRidingNoisesProtocol>
                                onSubmit={handleFinalFormSubmit}
                                initialValues={ridingNoisesProtocol}
                                mutators={defectMutator}
                                render={({handleSubmit, values, form}) => (
                                    <Form onSubmit={handleSubmit} id="protocol-form">
                                        <div className="pageheader  no-print">
                                            <div className="pageheader__left">
                                                <h1>Zkouškový protokol</h1>
                                                <h3>
                                                    Pracovní skupina{" "}
                                                    <span className="text--expert-group">
                              {ridingNoisesProtocol?.carTest.expertGroupName}
                            </span>{" "}
                                                </h3>
                                            </div>
                                            <div className="pageheader__right">
                                                <Can
                                                    roleId={user?.userRoleId}
                                                    perform="riding-noises-protocol:edit"
                                                    data={{
                                                        userExpertGroupId: user?.expertGroupId,
                                                    }}
                                                    yes={
                                                        <EditSaveResetButtons
                                                            edit={edit}
                                                            setEdit={setEdit}
                                                            handleReset={handleReset}
                                                            form={form}
                                                            isSaving={false}
                                                            printButton={true}
                                                        />
                                                    }
                                                    no={
                                                        <Button
                                                            variant="outline-info"
                                                            type="button"
                                                            onClick={() => window.print()}
                                                        >
                                                            <FontAwesomeIcon icon={faPrint} title="Tisk"/>{" "}
                                                            Tisk
                                                        </Button>
                                                    }
                                                />
                                                {!edit && (
                                                    <Fragment>

                                                        <Button
                                                            variant="outline-info"
                                                            type="button"
                                                            style={{
                                                            marginLeft: "1rem",
                                                            }}
                                                            onClick={() => handleGetDocument()}
                                                        >
                                                            <FontAwesomeIcon icon={faFileExcel} title="XLSX" />{" "}
                                                            XLSX
                                                        </Button>

                                                        <Button
                                                            variant="outline-secondary"
                                                            style={{
                                                                marginLeft: "1rem",
                                                            }}
                                                            key={"CarDetail_" + ridingNoisesProtocol?.car.carId}
                                                            as={Link}
                                                            to={`/car/${ridingNoisesProtocol?.car.carId}`}
                                                        >
                                                            Detail vozu
                                                        </Button>
                                                        <Button
                                                            variant="outline-secondary"
                                                            style={{
                                                                marginLeft: "1rem",
                                                            }}
                                                            key={
                                                                "CarTest_" +
                                                                ridingNoisesProtocol?.carTest.carTestId
                                                            }
                                                            as={Link}
                                                            to={`/carTest/${ridingNoisesProtocol?.carTest.carTestId}`}
                                                        >
                                                            Detail zkoušky
                                                        </Button>
                                                    </Fragment>
                                                )}
                                            </div>
                                        </div>

                                        <AlertMessage
                                            type="danger"
                                            heading="Nastala chyba při ukládání protokolu"
                                            show={showSubmitError}
                                            setShow={setShowSubmitError}
                                            messageList={errors}
                                        />

                                        <div className="pagecontent">
                                            {!isLoading && ridingNoisesProtocol && (
                                                <ProtocolHeaderCarInfo
                                                    car={ridingNoisesProtocol.car}
                                                    carTest={ridingNoisesProtocol.carTest}
                                                    measurementDate={undefined}
                                                />
                                            )}

                                            {!isLoading && ridingNoisesProtocol && existsCarProtocolId && 
                                                <Fragment>
                                                    <h2>Přílohy{" "}
                                                        <button
                                                        onClick={() => setAttachmentsCollapsed(!attachmentsCollapsed)}
                                                        type="button"
                                                        aria-expanded={attachmentsCollapsed}
                                                        className={`btn-link btn-link--primary animate-${
                                                            attachmentsCollapsed ? "up" : "down"
                                                        }`}
                                                        >
                                                        {!attachmentsCollapsed && <FontAwesomeIcon icon={faChevronDown} />}
                                                        {attachmentsCollapsed && <FontAwesomeIcon icon={faChevronUp} />}
                                                        </button>
                                                    </h2>

                                                    <Collapse in={attachmentsCollapsed}>
                                                        <Row>
                                                        <AttachmentsList attachments={attachments} colSize={uploadPanelSize} deleteAttachments={deleteAttachment} />

                                                        <Col md={uploadPanelSize}>
                                                            <MultiFilesUploaderPanel carId={carId} carTestId={carTestId} carProtocolId={carProtocolId} carAnalysisId={undefined} carDefectId={undefined} addAttachment={addAttachment} />
                                                        </Col>
                                                        </Row>
                                                    </Collapse>
                                                </Fragment>
                                            }

                                            {ridingNoisesProtocol?.additionalInfoArea &&
                                                <MileageArea isEdit={edit}
                                                             engineNumber={ridingNoisesProtocol?.additionalInfoArea.additionalInfoValue.engineNumber}
                                                />
                                            }
                                            {ridingNoisesProtocol?.executedTestArea &&

                                                <RidingNoisesExecutedTestsArea
                                                    isEdit={edit}
                                                    testArea={ridingNoisesProtocol.executedTestArea}
                                                    protocolCodeTableValues={codeTableValues}
                                                />
                                            }

                                            <div className="protocol">
                                                {/*Tabulka zavad*/}
                                                {!isLoading &&
                                                    ridingNoisesProtocol &&
                                                    ridingNoisesProtocol?.defectsArea && (
                                                        <DefectsArea
                                                            isEdit={edit}
                                                            isDisabled={
                                                                values.defectsArea?.notPerform || false
                                                            }
                                                        />
                                                    )}

                                                {/*Poznamka k celkovemu protokolu*/}
                                                {!isLoading && (
                                                    <div style={{paddingTop: "1em"}}>
                                                        <h2>Poznámka</h2>
                                                        <Field<string>
                                                            name="note"
                                                            component={TextAreaInput}
                                                            disabled={!edit}
                                                            rows={5}
                                                        />
                                                    </div>
                                                )}

                                                {!isLoading && ridingNoisesProtocol && (
                                                    <DefectsCount />
                                                )}
                                                {!isLoading && ridingNoisesProtocol && (
                                                    <Table style={{marginTop: "50px"}}>
                                                        <tbody>
                                                        <tr>
                                                            <td style={{border: "0"}}>
                                                                <Form.Group controlId={"defectsCount"}>
                                                                    <Row>
                                                                        <Col xs={3}>
                                                                            <Form.Label>
                                                                                Odpovědná osoba:
                                                                            </Form.Label>
                                                                        </Col>
                                                                        <Col
                                                                            xs={5}
                                                                            style={{
                                                                                textAlign: "center",
                                                                                borderBottom: "1px solid lightgray",
                                                                            }}
                                                                        >
                                                                            <Form.Label>
                                                                                {
                                                                                    ridingNoisesProtocol.workersExecutingTest
                                                                                }
                                                                            </Form.Label>
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Group>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </Table>
                                                )}
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            />
                        </div>
                    </td>
                </tr>
                </tbody>

                <tfoot>
                <tr>
                    <td>
                        <div className="page-footer-space"></div>
                    </td>
                </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default observer(RidingNoisesProtocol);
