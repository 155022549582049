import "./MultiFilesUploaderPanel.scss";

import React, { useState, useCallback, Fragment, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDropzone, FileRejection, FileError } from "react-dropzone";
import { ReactComponent as UploadIcon } from "../../assets/icons/cloud-upload.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { IFileUpload, FileUpload, IAttachment, FileState } from "../../app/models/attachment";
import FilesUploadingList from "./FilesUploadingList";

  interface IProps {
    carId: number | undefined;
    carTestId: number | undefined;
    carProtocolId: number | undefined;
    carAnalysisId: number | undefined;
    carDefectId: number | undefined;
    addAttachment: (attachment: IAttachment) => void;
  }

const MultiFilesUploaderPanel: React.FC<IProps> = ({
  carId,
  carTestId,
  carProtocolId,
  carAnalysisId,
  carDefectId,
  addAttachment
}) => {
  
  const [files, setFiles] = useState<IFileUpload[]>([]);
  
  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      const filesToUpload: IFileUpload[] =
        acceptedFiles.map((file, index: number) => {
          const selectedFile = new FileUpload(
            file,
            carId,
            carTestId,
            carProtocolId,
            carAnalysisId,
            carDefectId,
            FileState.Selected,
            []
          );
          return selectedFile;
        }) || [];
      const rejectedfilesToUpload: IFileUpload[] =
        rejectedFiles.map((file, index: number) => {
          const selectedFile = new FileUpload(
            file.file,
            carId,
            carTestId,
            carProtocolId,
            carAnalysisId,
            carDefectId,
            FileState.Error,
            file.errors.map((error) => {
              return { code: error.code, message: czErrorMessage(error) };
            })
          );
          return selectedFile;
        }) || [];
      setFiles((previousFiles) => [
        ...previousFiles,
        ...filesToUpload,
        ...rejectedfilesToUpload,
      ]);
    },
    []
  );

  const deleteFileToUpload = (uid: string) => {
    setFiles(files.filter((f) => f.uid !== uid));
  };

  const setupUploadingFileState = (changedFile: IFileUpload) => {
    if(changedFile.state === FileState.Done && changedFile.attachment)
    {
      addAttachment(changedFile.attachment);
    }
    if(changedFile.state === FileState.Done)
    {
      setFiles(files.filter((f) => f.uid !== changedFile.uid));
    }
    else{
      setFiles(files.map(file => {
        if (file.uid === changedFile.uid) {
          return { ...file, state: changedFile.state, attachment: changedFile.attachment };
        } else {
          return file;
        }
      }));
    }
  }

  const nameLengthValidator = (file: File) => {
    const maxLength: number = 100;
    if (file.name.length > maxLength) {
      return {
        code: "name-too-large",
        message: `Název souboru je delší než ${maxLength} znaků.`
      };
    }
    return null
  }

  const czErrorMessage = (error: FileError) => {
    switch (error.code) {
      case "file-invalid-type":
        return `Vložený soubor není povoleným typem souboru.`;
      case "file-too-large":
        return `Vložený soubor je větší než 512 MB.`;
      case "file-too-small":
        return `Vložený soubor je příliš malí.`;
      case "too-many-files":
        return "Je vloženo příliš souborů najednou.";
      default:
        return error.message;
    }
  };

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop, 
    accept: {
      "application/zip": ['.zip'],
      "application/msword": ['.doc'],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": ['.docx'],
      "application/vnd.ms-excel": ['.xls'],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ['.xlsx'],
      "application/vnd.ms-powerpoint": ['.ppt'],
      "application/vnd.openxmlformats-officedocument.presentationml.presentation": ['.pptx'],
      "application/pdf": ['.pdf'],
      "application/xml": ['.xml'],
      "text/csv": ['.csv'],
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/gif': ['.gif'],
      'image/bmp': ['.bmp'],
      'image/tiff': ['.tif', '.tiff'],
      'text/html': ['.html', '.htm'],
    },
    maxSize: 512 * 1024 * 1024,
    validator: nameLengthValidator
  });

/*
  useEffect(() => {
    console.log(files);
  }, [files]);
*/

  return (
    <Fragment>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <UploadIcon className="upload-icon" />
        <div className="dropzone-item">
          {isDragActive ? (
            <p>Přesunout soubory sem ...</p>
          ) : (
            <p>Přesunout soubory sem, nebo kliknout pro výběr souborů ...</p>
          )}
        </div>
        <div className="dropzone-item">
          <Button
            variant="primary"
            onClick={() => open}
            className="footer__btn-add"
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </div>
      </div>

      {files && files.length > 0 && (
        <FilesUploadingList files={files} deleteFileToUpload={deleteFileToUpload} setupUploadingFileState={setupUploadingFileState} />
      )}
    </Fragment>
  );
};

export default MultiFilesUploaderPanel;