import React, { useState, Fragment, useEffect } from "react";
import { FileState, IAttachment, IFileUpload } from "../../app/models/attachment";
import { Row, Col, Button, ListGroup, ProgressBar } from "react-bootstrap";
import { FileIcon, defaultStyles } from "react-file-icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import agent from "../../app/api/agent";

interface IProps {
  file: IFileUpload;
  startUploading: boolean;
  deleteFileToUpload: (uid: string) => void;
  setupUploadingFileState: (file: IFileUpload) => void;
}

const FileUploadingListItem: React.FC<IProps> = ({ file, startUploading, deleteFileToUpload, setupUploadingFileState }) => {

    const [progress, setProgress] = useState(0);
    const [ errors, setErrors ] = useState<string[]>([])
    const existsError: boolean = errors && errors.length > 0;

    useEffect(() => {
        //console.log(startUploading);
        if(startUploading && file.state === FileState.Selected)
        {
            async function upload() {
                //console.log(`Start uploding file ${file.name}`);
                await agent.Attachments.carAttachementUpload(file,setProgress)
                  .then(attachment => {
                    file.state = FileState.Done;
                    file.attachment = attachment;
                    setupUploadingFileState(file);
                  })
                  .catch((error) => {
                    setErrors(Object.values(error.response.data.errors));
                    file.state = FileState.Error;
                    setupUploadingFileState(file);
                });
            }
            upload();
          
        }

      }, [file, startUploading]);


  return (
    <ListGroup.Item key={file.uid} as="li" className="m-0 p-0 border-0">
      <Row className="d-flex align-items-center justify-content-start align-items-start">
        <Col className="m-0 p-0 d-flex justify-content-center" sm={1}>
          <div className="fileIcon">
            <FileIcon
              extension={file.extension}
              {...defaultStyles[file.extension]}
            />
          </div>
        </Col>
        <Col className="m-0 p-0" sm={7}>
          {file.name}
        </Col>
        <Col className="m-0 p-0 d-flex justify-content-end" sm={3}>
          {file.size}
        </Col>
        <Col className="m-0 p-0 d-flex justify-content-center" sm={1}>
          {(progress === 0 || existsError) && (
            <Button
              variant="link"
              size="sm"
              onClick={() => deleteFileToUpload(file.uid)}
              title="Odstranit soubor"
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          )}
          {(progress === 100 && !existsError ) && (
            <FontAwesomeIcon icon={faCheckCircle} color="#2e8b57" />
          )}
        </Col>
      </Row>
      {file.state === FileState.Error && file.errors.length > 0 && 
      <Row>
        <ul className="FileUploadErrorList">
          {file.errors.map(error => {
            return (<li>{error.message}</li>)
          })}
        </ul>
      </Row>
      }
      {existsError &&
        <Row>
          <ul className="FileUploadErrorList">
            {errors.map(error => {
              return (<li><b>Chyba uloení přílohy</b> - {error}</li>)
            })}
        </ul>
        </Row>
      }
      {startUploading && file.state === FileState.Selected && (
        <Row>
          <Col>
            <ProgressBar
              striped={progress < 100 ? true : false}
              variant={(file.errors.length > 0 || existsError) ? "danger" : "success"}
              now={progress}
              label={`${progress}%`}
            />
          </Col>
        </Row>
      )}
      
    </ListGroup.Item>
  );
};

export default FileUploadingListItem;
