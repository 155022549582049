import React, { Fragment } from "react";
import { Row, Col, Table, Container } from "react-bootstrap";
import { Field, useForm } from "react-final-form";
import { IEngineUnitAndChassisProtocol, IProtocolPartGroup  } from "../../../../../app/models/protocol";
import DefectButton from "../../protocolParts/defectForm/DefectButton";
import CheckboxInput from "../../../../../app/common/form/CheckboxInput";
import AutocompleteSimpleInput from "../../../../../app/common/form/AutocompleteSimpleInput";
import SelectInput from "../../../../../app/common/form/SelectInput";
import TextInput from "../../../../../app/common/form/TextInput";

interface IProps {
    group: IProtocolPartGroup;
    areaIndex: number;
    groupIndex: number;
    isEdit: boolean;
    isDisabledArea: boolean;
    isDisabledGroup: boolean;
}

const WheelsTiresGroup: React.FC<IProps> = ({ group, areaIndex, groupIndex, isEdit, isDisabledArea, isDisabledGroup }) => {

    const form = useForm<IEngineUnitAndChassisProtocol>();
    let isDisabled: boolean = isDisabledArea || isDisabledGroup;
    
    return (
    <Fragment>
        <Container className={`pt-2 ${ isDisabled ? "disabled" : ""}`} key={"WheelsTiresGroup"}>    
            <Row noGutters className={'mt-2 mb-2 border-bottom'}>
                <Col sm={6}>
                    <h3>{group.name}</h3>
                </Col>
                <Col sm={2}></Col>
                <Col sm={2}></Col>
                <Col sm={2}>
                    <Field
                        name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].notPerform`}
                        title="Neprovádět"
                        options={[
                            {key: 1, value: "ano"},
                            {key: 0, value: " ne "},
                        ]}
                        format={(v) => (v ? 1 : 0)}
                        parse={(v) => (!!v)}
                        type={"checkbox"}
                        initialValue={group.notPerform || false}
                        component={CheckboxInput}
                        inputOnChange={(e: any) => {
                            form.mutators.handleChangePerformAreaOrGroup(e);
                        }}
                        disabled={!isEdit || isDisabledArea}
                        labelWidth={8}
                        valueWidth={4}
                    />
                </Col>
            </Row>

            <Row>
                <Table id="protocol-table">
                    <thead>
                        <tr className={`protocol-rule protocol-rule--header`}>
                            <td colSpan={3}></td>
                            <td>
                                <h4>Hodnocení</h4>
                                <small className="text-muted">
                                    Vyhovuje / Závada
                                </small>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {group.partRules && group.partRules.map((rule, ruleIndex) => (
                            <tr>
                                <td colSpan={3}>
                                    <Field<string>
                                        name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].partRules[${ruleIndex}].measuredValue`}
                                        component={TextInput}
                                        initialValue={rule.measuredValue}
                                        rows={1}
                                        size="sm"
                                        disabled={!isEdit || isDisabled}
                                    />
                                </td>
                                <td>
                                    <Row>
                                        <Col sm={5} style={{ paddingRight: 3 }}>
                                        <Field<string>
                                            name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].partRules[${ruleIndex}].stringEval`}
                                            initialValue={rule.stringEval?.toString()}
                                            component={AutocompleteSimpleInput}
                                            items={["OK", "NOK"]}
                                            rows={1}
                                            size="sm"
                                            disabled={!isEdit || isDisabled}
                                        />
                                        </Col>
                                        <Col sm={5} style={{ paddingLeft: 3 }}>
                                        <Field
                                            name={`areas[${areaIndex}].protocolPartGroups[${groupIndex}].partRules[${ruleIndex}].signDefect`}
                                            inputOnChange={(e: any) => {
                                            form.mutators.calculateDefects(e);
                                            }}
                                            options={[
                                            { key: null, value: "" },
                                            { key: 1, value: "1 - A1" },
                                            { key: 2, value: "2 - A" },
                                            { key: 3, value: "3 - A" },
                                            { key: 4, value: "4 - B1" },
                                            { key: 5, value: "5 - B" },
                                            { key: 6, value: "6 - C1" },
                                            { key: 7, value: "7 - C" },
                                            { key: 8, value: "8 - C" },
                                            { key: 9, value: "9 - C" },
                                            { key: 10, value: "10 - C" },
                                            ]}
                                            component={SelectInput}
                                            disabled={!isEdit || isDisabled}
                                        />
                                        </Col>
                                        
                                        <Col sm={1} style={{paddingLeft: 0, paddingRight: 0}} className="no-print">
                                            <DefectButton
                                                partRule={rule}
                                                isEdit={isEdit}
                                                isDisabled={!isEdit || isDisabled}
                                            />
                                        </Col>
                                        
                                    </Row>
                                    </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Row>
        </Container>
    </Fragment>
    );
  };
  
  export default WheelsTiresGroup;