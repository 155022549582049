import { RootStore } from "./rootStore";
import { observable, action, runInAction, computed } from "mobx";
import agent from "../api/agent";
import {
  ICar,
  ICarForm,
  ICarIdVin,
  ICarUpdateResponse,
  ICarFilter,
  IHistory,
} from "../models/car";
import { ICarTest } from "../models/carTest";
import { ICarAnalysis } from "../models/carAnalysis"
import { toast } from "react-toastify";
import {
  getMasculinePassiveVerbEnding,
  getCarsString,
} from "../common/utils/czechDeclension";
import { IAttachment } from "../models/attachment";

export default class CarStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable carRegistry = new Map<number, ICar>();
  @observable carTestsRegistry = new Map<number, ICarTest[]>();
  @observable carAnalysisRegistry = new Map<number, ICarAnalysis[]>();
  @observable carsByVinOrKnr: ICarIdVin[] = [];
  @observable carHistoryRegistry = new Map<number, IHistory[]>();
  @observable carAttachmentsRegistry = new Map<number, IAttachment[]>();
  @observable carCops: ICar | undefined = undefined;
  @observable loadingInitial = false;
  @observable isSaving = false;

  @computed get carSorted() {
    return this.sortCarsById(Array.from(this.carRegistry.values()));
  }

  sortCarsById(cars: ICar[]) {
    const sortedCars = cars.sort((a, b) => b.carId - a.carId);
    return sortedCars;
  }

  getAxiosCarFilterParams(carFilter: ICarFilter) {
    const params = new URLSearchParams();
    if (carFilter.vin) params.append("vin", carFilter.vin);
    if (carFilter.prodIdentificationNum)
      params.append("prodIdentificationNum", carFilter.prodIdentificationNum);
    if (carFilter.carNumber) params.append("carNumber", carFilter.carNumber);
    if (carFilter.inTestroom) params.append("inTestroom", "true");
    if (carFilter.customerCode)
      params.append("customerCode", carFilter.customerCode);
    if (carFilter.carStates)
      carFilter.carStates.forEach((carState) => {
        params.append("carStateIds", carState.id.toString());
      });
    if (carFilter.carModels)
      carFilter.carModels.forEach((carModel) => {
        params.append("carModelIds", carModel.id.toString());
      });
    if (carFilter.carEngines)
      carFilter.carEngines.forEach((carEngine) => {
        params.append("carEngineIds", carEngine.id.toString());
      });
    if (carFilter.testDestinations)
      carFilter.testDestinations.forEach((testDestination) => {
        params.append("destinationTypeIds", testDestination.id.toString());
      });
    if (carFilter.testPredestinations)
      carFilter.testPredestinations.forEach((testPredestination) => {
        params.append(
          "predestinationTypeIds",
          testPredestination.id.toString()
        );
      });
    if (carFilter.testTypes)
      carFilter.testTypes.forEach((testType) => {
        params.append("carTestTypeIds", testType.id.toString());
      });
    if (carFilter.timeLimitTypes)
      carFilter.timeLimitTypes.forEach((timeLimitType) => {
        params.append("timeLimitTypeIds", timeLimitType.id.toString());
      });
    if (carFilter.conflictTypes)
      carFilter.conflictTypes.forEach((conflictType) => {
        params.append("conflictTypeIds", conflictType.id.toString());
      });
    return params;
  }

  @action loadCars = async () => {
    this.loadingInitial = true;
    try {
      const carList = await agent.Cars.list(new URLSearchParams());
      runInAction("load cars error", () => {
        carList.cars.forEach((car) => this.carRegistry.set(car.carId, car));
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load cars error", () => {
        this.loadingInitial = false;
      });
      throw error;
    }
  };

  @action loadCar = async (id: number) => {
    this.loadingInitial = true;
    try {
      const carDTO = await agent.Cars.details(id);
      runInAction("load cars error", () => {
        const { car, carTests, carHistories, carAnalysis, attachments } = carDTO;

        this.carRegistry.set(car.carId, car);
        this.carTestsRegistry.set(car.carId, carTests);
        this.carAnalysisRegistry.set(car.carId, carAnalysis);
        this.carHistoryRegistry.set(car.carId, carHistories);
        this.carAttachmentsRegistry.set(car.carId, attachments);

        carTests.forEach((carTest) =>
          this.rootStore.carTestStore.carTestsRegistry.set(
            carTest.carTestId,
            carTest
          )
        );
        
        carAnalysis.forEach((analysis) =>
          this.rootStore.carAnalysisStore.carAnalysesRegistry.set(
            analysis.carAnalysisId!,
            analysis
          )
        );

        this.loadingInitial = false;
//        console.log(carDTO);
        return carDTO;
      });
    } catch (error) {
      runInAction("load cars error", () => {
        this.loadingInitial = false;
      });
      throw error;
    }
    return undefined;
  };

  @action updateCar = async (car: ICarForm): Promise<ICarUpdateResponse> => {
    try {
      this.isSaving = true;
      const response = await agent.Cars.update(car);
      runInAction("editing car", () => {
        this.carRegistry.set(response.car.carId, response.car);
      });
      toast.success(`Vůz ${car.vin || car.prodIdentificationNum} byl uložen`);
      this.isSaving = false;
      return response;
    } catch (error) {
      console.log(error);
      this.isSaving = false;
      toast.error("Při ukládání vozu nastala chyba!");
      throw error;
    }
  };

  @action loadCarsByVinOrKnr = async (vin?: string, knr?: string) => {
    try {
      const result = await agent.Cars.listByVinOrKnr(vin, knr);
      runInAction("load cars by vin or knr", () => {
        this.carsByVinOrKnr = result.cars;
      });
      return result.cars;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  @action editCarNote = async (carId: number, note: string) => {
    try {
      await agent.Cars.updateNote(carId, note);
      runInAction("updating car note", () => {
        const car = this.carRegistry.get(carId);
        // update note in car registry
        if (car) {
          this.carRegistry.set(carId, { ...car, note: note });
        }
      });
      toast.success(`Poznámka byla uložena`);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  @action takeCarsInTestroom = async (carIds: number[]) => {
    try {
      const response = await agent.Cars.takeCarsInTestroom(carIds);

      runInAction("load cars take in test room", () => {
        response.cars.forEach((car) => this.carRegistry.set(car.carId, car));
      });

      toast.success(
        `${carIds.length} ${getCarsString(
          carIds.length
        )} byl${getMasculinePassiveVerbEnding(
          carIds.length
        )} naskladněn${getMasculinePassiveVerbEnding(carIds.length)}`
      );
      return response;
    } catch (error) {
      console.log(error);
      toast.error("Při naskladňování vozů nastala chyba!");
      throw error;
    }
  };

  @action takeCarsOutOfTestroom = async (carIds: number[]) => {
    try {
      const response = await agent.Cars.takeCarsOutOfTestroom(carIds);

      runInAction("load cars take in test room", () => {
        response.cars.forEach((car) => this.carRegistry.set(car.carId, car));
      });

      toast.success(
        `${carIds.length} ${getCarsString(
          carIds.length
        )} byl${getMasculinePassiveVerbEnding(
          carIds.length
        )} vyskladněn${getMasculinePassiveVerbEnding(carIds.length)}`
      );
      return response;
    } catch (error) {
      console.log(error);
      toast.error("Během vyskladňování vozů nastala chyba!");
      throw error;
    }
  };

  @action sendCarsToEmission = async (carIds: number[]) => {
    try {
      const response = await agent.Cars.sendToEmission(carIds);

      runInAction("send cars to emission", () => {
        response.cars.forEach((car) => this.carRegistry.set(car.carId, car));
      });

      toast.success(
        `${carIds.length} ${getCarsString(
          carIds.length
        )} byl${getMasculinePassiveVerbEnding(
          carIds.length
        )} odeslán${getMasculinePassiveVerbEnding(carIds.length)} na emise`
      );
      return response;
    } catch (error) {
      console.log(error);
      toast.error("Během odesílání vozů ne emise nastala chyba!");
      throw error;
    }
  };

  @action loadCarsFromEmission = async (carIds: number[]) => {
    try {
      const response = await agent.Cars.loadFromEmission(carIds);

      runInAction("load cars from emission", () => {
        response.cars.forEach((car) => this.carRegistry.set(car.carId, car));
      });

      toast.success(
        `${carIds.length} ${getCarsString(
          carIds.length
        )} byl${getMasculinePassiveVerbEnding(
          carIds.length
        )} přijat${getMasculinePassiveVerbEnding(carIds.length)} z emisí`
      );
      return response;
    } catch (error) {
      console.log(error);
      toast.error("Během přijímání vozů z emisí nastala chyba!");
      throw error;
    }
  };

  @action transportCars = async (carIds: number[]) => {
    try {
      const response = await agent.Cars.transport(carIds);

      runInAction("transport cars ", () => {
        response.cars.forEach((car) => this.carRegistry.set(car.carId, car));
      });

      toast.success(
        `${carIds.length} ${getCarsString(
          carIds.length
        )} byl${getMasculinePassiveVerbEnding(
          carIds.length
        )} transportován${getMasculinePassiveVerbEnding(
          carIds.length
        )} na druhou zkušebnu`
      );
      return response;
    } catch (error) {
      console.log(error);
      toast.error("Během transportu vozů nastala chyba!");
      throw error;
    }
  };

  @action cancelCarTransport = async (carId: number, reason: string) => {
    try {
      const response = await agent.Cars.cancelCarTransport(carId, reason);
      runInAction("cancel transport cars ", () => {
        response.cars.forEach((car) => {
          this.carRegistry.set(car.carId, car);
          if(car.placeId !== this.rootStore.authStore.user?.placeId)
          this.carRegistry.delete(carId);
        });
      });
      toast.success(`Transport vozu ${response.cars[0]?.vin} byl úspěšně zrušen`);
      return response;
    } catch (error) {
      console.log(error);
      toast.error("Během rušení transportu vozu nastala chyba!");
      throw error;
    }
  };
  @action filterCars = (carFilter: ICarFilter): number[] => {
    const filteredCarIds = Array.from(this.carRegistry.values())
      .filter(
        (v) =>
          !carFilter.vin ||
          (v.vin &&
            v.vin
              .toLocaleUpperCase("cs")
              .includes(carFilter.vin.toLocaleUpperCase("cs")))
      )
      .filter(
        (v) =>
          !carFilter.carNumber ||
          (v.vin &&
            v.vin
              .toLocaleUpperCase("cs")
              .includes(carFilter.carNumber.toLocaleUpperCase("cs"), 9))
      )
      .filter(
        (v) =>
          !carFilter.carStates ||
          !carFilter.carStates.length ||
          carFilter.carStates.map((s) => s.id).includes(v.carStateId)
      )
      .map((c) => c.carId);
    return filteredCarIds;
  };

  @action getCarByVinOrKnrFromCops = async (vin?: string, knr?: string) => {
    try {
      this.loadingInitial = true;
      const result = await agent.Cars.getCarFromCops(vin, knr);
      runInAction("load car from cops by vin or knr", () => {
        this.loadingInitial = false;
        this.carCops = result;
      });
      return this.carCops;
    } catch (error) {
        console.log(error);
        this.loadingInitial = false;
        throw error;
    }
  };
}
