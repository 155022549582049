import { MutableState, Tools } from "final-form";
import { GetAreaGroupRuleIndexFromFieldName, validatePartRules, validateRule } from "../../partialProtocols/protocolFunctions"
import { IEngineUnitAndChassisProtocol, IProtocolArea, IProtocolPartRule, IEngineUnitAndChassisAxleLoadWeightsGroupDataExtension} from "../../../../app/models/protocol";

export const engineUnitAndChassisMutators = {
    handleChangePerformAreaOrGroup: (args: any , state: MutableState<IEngineUnitAndChassisProtocol>, utils: Tools<IEngineUnitAndChassisProtocol>) => {
        const fieldValue: boolean = args[0].target.checked;
        const fieldName = args[0].currentTarget.name;

        let fieldNameItems = fieldName.split(".");
        let name = fieldNameItems.pop();
        let prevFieldName = fieldNameItems.join(".")

        if(name === 'perform')
        {
            utils.changeValue(state,`${prevFieldName}.notPerform`,() => !fieldValue);
        }
        if(name === 'notPerform')
        {
            utils.changeValue(state,`${prevFieldName}.perform`,() => !fieldValue);
        }

    },

    checkMeasuredValue: (args: any , state: MutableState<IEngineUnitAndChassisProtocol>, utils: Tools<IEngineUnitAndChassisProtocol>) : Boolean | undefined => {
      const fieldName = args[0].currentTarget.name;
      let {areaIndex, groupIndex, ruleIndex, measuredValIndex} = GetAreaGroupRuleIndexFromFieldName(fieldName);
      var partRule: IProtocolPartRule | undefined;
      var partRuleFieldName: string = "";
      if (state.lastFormState?.values.areas && areaIndex >= 0) {
        const area: IProtocolArea = state.lastFormState?.values?.areas[areaIndex];
        if (area.protocolPartGroups && groupIndex !== undefined) {
          const group = area.protocolPartGroups[groupIndex];
          if(group && group.partRules && ruleIndex !== undefined)
          {
            partRule = group.partRules[ruleIndex];
            partRuleFieldName = `areas[${areaIndex}].protocolPartGroups[${groupIndex}].partRules[${ruleIndex}]`;
          }
        }
        if(area.partRules && ruleIndex !== undefined)
        {
          partRule = area.partRules[ruleIndex];
          partRuleFieldName = `areas[${areaIndex}].partRules[${ruleIndex}]`;
        }
      }
      if (partRule &&  partRule.measuredValue) {
        let validationResult = validateRule(partRule, partRule.measuredValue, 0);
        var val: string = "";
        if (validationResult === true) {
          val = "NOK";
        } else if (validationResult === false) {
          val = "OK";
        }
        const evalField = partRuleFieldName + ".stringEval";
        utils.changeValue(state, evalField, () => val);
        return validationResult;
      }
      return undefined;
    },

    checkMeasuredValues: (args: any , state: MutableState<IEngineUnitAndChassisProtocol>, utils: Tools<IEngineUnitAndChassisProtocol>) : Boolean | undefined => {
      const fieldName = args[0].currentTarget.name;
      let {areaIndex, groupIndex, ruleIndex, measuredValIndex} = GetAreaGroupRuleIndexFromFieldName(fieldName);
      var partRule: IProtocolPartRule | undefined;
      var partRuleFieldName: string = "";
      if (state.lastFormState?.values.areas && areaIndex >= 0) {
        const area: IProtocolArea = state.lastFormState?.values?.areas[areaIndex];
        if (area.protocolPartGroups && groupIndex !== undefined) {
          const group = area.protocolPartGroups[groupIndex];
          if(group && group.partRules && ruleIndex !== undefined)
          {
            partRule = group.partRules[ruleIndex];
            partRuleFieldName = `areas[${areaIndex}].protocolPartGroups[${groupIndex}].partRules[${ruleIndex}]`;
          }
        }
        if(area.partRules && ruleIndex !== undefined)
        {
          partRule = area.partRules[ruleIndex];
          partRuleFieldName = `areas[${areaIndex}].partRules[${ruleIndex}]`;
        }
      }
      if(partRule && partRule.measuredValues)
      {
        if(partRule.measuredValues.length > 1)
        {
          var measuredValue: string = partRule.measuredValues.join(" ; ");
          const measuredValueField =
          partRuleFieldName + ".measuredValue";
          utils.changeValue(state, measuredValueField, () => measuredValue);
        }
        let validationResult = validatePartRules(partRule);
        var val: string = "";
        if (validationResult === true) {
          val = "NOK";
        } else if (validationResult === false) {
          val = "OK";
        }
        const evalField = partRuleFieldName + ".stringEval";
        utils.changeValue(state, evalField, () => val);
        return validationResult;
      }
      return undefined;
    },

    calculateWasherWeights: (args: any , state: MutableState<IEngineUnitAndChassisProtocol>, utils: Tools<IEngineUnitAndChassisProtocol>) => {
      const fieldName = args[0].currentTarget.name;
      let {areaIndex, groupIndex, ruleIndex, measuredValIndex} = GetAreaGroupRuleIndexFromFieldName(fieldName);

      if (state.lastFormState?.values.areas && areaIndex >= 0) {
      const area: IProtocolArea = state.lastFormState?.values?.areas[areaIndex];
      if (area.protocolPartGroups && groupIndex !== undefined) {
        const group = area.protocolPartGroups[groupIndex];
        if(group && group.dataExtension)
        {
          let data: IEngineUnitAndChassisAxleLoadWeightsGroupDataExtension = group.dataExtension;
          let measured: number = parseFloat(args[0].currentTarget.value);
          if(data.washersTankCapacity && data.washersMinimumDensity && measured)
          {
            let washerWeights: number = (data.washersTankCapacity - measured) * data.washersMinimumDensity;
            utils.changeValue(state,`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.washersWeights`,() => washerWeights.toFixed(1));
          }
          else
          {
            utils.changeValue(state,`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.washersWeights`,() => undefined);
          }
        }
      }}
    },

    calculateFuelWeights: (args: any , state: MutableState<IEngineUnitAndChassisProtocol>, utils: Tools<IEngineUnitAndChassisProtocol>) => {
      const fieldName = args[0].currentTarget.name;
      let {areaIndex, groupIndex, ruleIndex, measuredValIndex} = GetAreaGroupRuleIndexFromFieldName(fieldName);

      if (state.lastFormState?.values.areas && areaIndex >= 0) {
      const area: IProtocolArea = state.lastFormState?.values?.areas[areaIndex];
      if (area.protocolPartGroups && groupIndex !== undefined) {
        const group = area.protocolPartGroups[groupIndex];
        if(group && group.dataExtension)
        {
          let data: IEngineUnitAndChassisAxleLoadWeightsGroupDataExtension = group.dataExtension;
          let measured: number = parseFloat(args[0].currentTarget.value);
          if(data.fuelTankCapacity && data.fuelMinimumDensity && measured)
          {
            let fuelWeights: number = (data.fuelTankCapacity - measured) * data.fuelMinimumDensity;
            utils.changeValue(state,`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.fuelWeights`,() => fuelWeights.toFixed(1));
          }
          else
          {
            utils.changeValue(state,`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.fuelWeights`,() => undefined);
          }
        }
      }}
    },

    calculateAdBlueWeights: (args: any , state: MutableState<IEngineUnitAndChassisProtocol>, utils: Tools<IEngineUnitAndChassisProtocol>) => {
      const fieldName = args[0].currentTarget.name;
      let {areaIndex, groupIndex, ruleIndex, measuredValIndex} = GetAreaGroupRuleIndexFromFieldName(fieldName);

      if (state.lastFormState?.values.areas && areaIndex >= 0) {
      const area: IProtocolArea = state.lastFormState?.values?.areas[areaIndex];
      if (area.protocolPartGroups && groupIndex !== undefined) {
        const group = area.protocolPartGroups[groupIndex];
        if(group && group.dataExtension)
        {
          let data: IEngineUnitAndChassisAxleLoadWeightsGroupDataExtension = group.dataExtension;
          let measured: number = parseFloat(args[0].currentTarget.value);
          if(data.adBlueTankCapacity && data.adBlueMinimumDensity && measured)
          {
            let adBlueWeights: number = (data.adBlueTankCapacity - measured) * data.adBlueMinimumDensity;
            utils.changeValue(state,`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.adBlueWeights`,() => adBlueWeights.toFixed(1));
          }
          else
          {
            utils.changeValue(state,`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.adBlueWeights`,() => undefined);
          }
        }
      }}
    },

    calculateWeightMeasureFrontAxle: (args: any , state: MutableState<IEngineUnitAndChassisProtocol>, utils: Tools<IEngineUnitAndChassisProtocol>) => {
        const fieldName = args[0].currentTarget.name;
        let {areaIndex, groupIndex, ruleIndex, measuredValIndex} = GetAreaGroupRuleIndexFromFieldName(fieldName);

        if (state.lastFormState?.values.areas && areaIndex >= 0) {
        const area: IProtocolArea = state.lastFormState?.values?.areas[areaIndex];
        if (area.protocolPartGroups && groupIndex !== undefined) {
          const group = area.protocolPartGroups[groupIndex];
          if(group && group.dataExtension)
          {
            let data: IEngineUnitAndChassisAxleLoadWeightsGroupDataExtension = group.dataExtension;
            if(data.weightMeasureLeftFront && data.weightMeasureRightFront)
            {
              let weightMeasureFrontAxle =  data.weightMeasureLeftFront + data.weightMeasureRightFront;
              utils.changeValue(state,`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.weightMeasureFrontAxle`,() => weightMeasureFrontAxle);
              if(data.sumUnsprungWeightFrontAxle)
              {
                let withoutUnsprungWeightFrontAxle = weightMeasureFrontAxle + data.sumUnsprungWeightFrontAxle;
                utils.changeValue(state,`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.unsprungWeightFrontAxle`,() => withoutUnsprungWeightFrontAxle);
              }
              else
              {
                let withoutUnsprungWeightFrontAxle = weightMeasureFrontAxle;
                utils.changeValue(state,`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.unsprungWeightFrontAxle`,() => withoutUnsprungWeightFrontAxle);
              }
              if(data.weightMeasureRearAxle)
              {
                let total = weightMeasureFrontAxle + data.weightMeasureRearAxle
                utils.changeValue(state,`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.weightMeasureTotal`,() => total);
              }
            }
            else
            {
              utils.changeValue(state,`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.weightMeasureFrontAxle`,() => undefined);
              utils.changeValue(state,`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.unsprungWeightFrontAxle`,() => undefined);
              utils.changeValue(state,`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.weightMeasureTotal`,() => undefined);
            }
          }
        }}
    },

    calculateWeightMeasureRearAxle: (args: any , state: MutableState<IEngineUnitAndChassisProtocol>, utils: Tools<IEngineUnitAndChassisProtocol>) => {
      const fieldName = args[0].currentTarget.name;
      let {areaIndex, groupIndex, ruleIndex, measuredValIndex} = GetAreaGroupRuleIndexFromFieldName(fieldName);

      if (state.lastFormState?.values.areas && areaIndex >= 0) {
      const area: IProtocolArea = state.lastFormState?.values?.areas[areaIndex];
      if (area.protocolPartGroups && groupIndex !== undefined) {
        const group = area.protocolPartGroups[groupIndex];
        if(group && group.dataExtension)
        {
          let data: IEngineUnitAndChassisAxleLoadWeightsGroupDataExtension = group.dataExtension;
          if(data.weightMeasureLeftRear && data.weightMeasureRightRear)
          {
            let weightMeasureRearAxle =  data.weightMeasureLeftRear + data.weightMeasureRightRear;
            utils.changeValue(state,`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.weightMeasureRearAxle`,() => weightMeasureRearAxle);
            if(data.sumUnsprungWeightRearAxle)
            {
              let withoutUnsprungWeightRearAxle = weightMeasureRearAxle + data.sumUnsprungWeightRearAxle;
              utils.changeValue(state,`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.unsprungWeightRearAxle`,() => withoutUnsprungWeightRearAxle);
            }
            else
            {
              let withoutUnsprungWeightRearAxle = weightMeasureRearAxle;
              utils.changeValue(state,`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.unsprungWeightRearAxle`,() => withoutUnsprungWeightRearAxle);
            }
            if(data.weightMeasureFrontAxle)
            {
              let total = data.weightMeasureFrontAxle + weightMeasureRearAxle
              utils.changeValue(state,`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.weightMeasureTotal`,() => total);
            }
          }
          else
          {
            utils.changeValue(state,`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.weightMeasureRearAxle`,() => undefined);
            utils.changeValue(state,`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.unsprungWeightRearAxle`,() => undefined);
            utils.changeValue(state,`areas[${areaIndex}].protocolPartGroups[${groupIndex}].dataExtension.weightMeasureTotal`,() => undefined);
          }
        }
      }}
  }
};