import { FileError } from "react-dropzone/.";
import { v4 as uuidv4 } from "uuid";
import { getFileExtension, getFileSize } from "../../features/filesUpload/fileFunctions";

export interface IAttachment {
  attachmentId: number;
  carId: number | undefined;
  carTestId: number | undefined;
  carProtocolId: number | undefined;
  carAnalysisId: number | undefined;
  carProtocolDefectId: number | undefined;
  fileName: string;
  mimeType: string;
  length: number;
  extension: string;
  size: string;
}

export enum FileState {
  Selected = 'Selected',
  Error = 'Error',
  Uploading = "Uploading",
  Done = 'Done'
}

export interface IFileUpload{
  uid: string;
  carId: number | undefined;
  carTestId: number | undefined;
  carProtocolId: number | undefined;
  carAnalysisId: number | undefined;
  carDefectId: number | undefined;
  file: File | undefined;
  name: string;
  type: string;
  length: number;
  extension: string;
  size: string;
  state: FileState;
  errors: FileError[];
  attachment: IAttachment | undefined;
}

export class FileUpload implements IFileUpload {
  uid: string = "";
  carId: number | undefined;
  carTestId: number | undefined;
  carProtocolId: number | undefined;
  carAnalysisId: number | undefined;
  carDefectId: number | undefined;
  file: File | undefined = undefined;
  name: string = "";
  type: string = "";
  length: number = 0;
  extension: string = "";
  size: string = "";
  state: FileState = FileState.Selected;
  errors: FileError[] = [];
  attachment: IAttachment | undefined = undefined;

  constructor(file: File,
              carId: number | undefined,
              carTestId: number | undefined,
              carProtocolId: number | undefined,
              carAnalysisId: number | undefined,
              carDefectId: number | undefined,
              state: FileState,
              errors: FileError[])
  {
    this.uid = uuidv4();
    this.carId = carId;
    this.carTestId = carTestId;
    this.carProtocolId = carProtocolId;
    this.carAnalysisId = carAnalysisId;
    this.carDefectId = carDefectId;
    this.file = file;
    this.name = file.name;
    this.type = file.type;
    this.length = file.size;
    this.extension = getFileExtension(file.name);
    this.size = getFileSize(file.size);
    this.state = state;
    this.errors = errors;
  }
}
