import "./protocolRuleTab.scss";
import React, {Fragment, useContext} from "react";
import {Field, Form as FinalForm} from "react-final-form";
import {Button, Form, OverlayTrigger, Table, Tooltip} from "react-bootstrap";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {
  AreaEnumDTO,
  GroupEnumDTO,
  IProtocolCopyRulesDTO,
  IProtocolRuleConditionSettingDTO,
  IProtocolRulesSetting,
  IRuleSettingDTO,
  PartEnumDTO
} from "../../../app/models/protocol";
import {faPen, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ProtocolRuleForm from "./ProtocolRuleForm";
import ProtocolCopyRulesForm from "./ProtocolCopyRulesForm";
import Loader from "../../loader/Loader";
import SwitchInput from "../../../app/common/form/SwitchInput";
import {observer} from "mobx-react-lite";
import {toast} from "react-toastify";
import {useProtocolRuleSettingsQuery} from "../../../app/api/client";
import {v4 as uuidv4} from 'uuid';
import ProtocolRuleConditionDataForm from "./ProtocolRuleConditionDataForm";

interface IProps {
  protocolType?: number | undefined;
  carModelId: number | undefined;
  carBodyTypeId: number | undefined;
  areaEnums?: AreaEnumDTO[] | undefined;
  groupEnums?:  GroupEnumDTO[] | undefined;
  partEnums?:  PartEnumDTO[] | undefined;
}

const ProtocolRuleTab: React.FC<IProps> = ({
  protocolType,
  carModelId,
  carBodyTypeId,
  areaEnums,
  groupEnums,
  partEnums,
}) => {
  const rootStore = useContext(RootStoreContext);
  const enums = rootStore.enumStore.enums;
  const { openModal } = rootStore.modalStore;

  const {data: protocolRulesSetting, isFetching: isLoading} = useProtocolRuleSettingsQuery(protocolType || 0, carModelId, carBodyTypeId)

  if (!protocolRulesSetting || isLoading) return <Loader></Loader>
  const handleAddNewRule = () => {
    let rule: IRuleSettingDTO | undefined = {
      protocolRuleId: 0,
      protocolTypeId: protocolType,
      carModelId: carModelId,
      carBodyTypeId: carBodyTypeId,
      protocolAreaId: undefined,
      protocolAreaName: "",
      enableDoorProtect: false,
      protocolPartGroupId: undefined,
      protocolPartGroupName: "",
      protocolPartId: undefined,
      protocolPartName: "",
      rule: "",
      unit: "",
      protocolRuleFunctionId: undefined,
      protocolRuleFunctionCode: "",
      protocolRuleFunctionName: "",
      numberOfValuesPerRule: undefined,
      forPlacesList: [],
      protocolRuleType: 1,
      additionalInfo: "",
      connectionToCondition: false,
      prFamilyId: undefined,
      prFamiliesCodes: "",
      protocolRuleConditions: [],
      isActive: true,
      order: 0
    }
    if(rule){
    openModal(
      <ProtocolRuleForm protocolRule={rule} enums={enums} areasEnum={areaEnums} groupsEnum={groupEnums} partsEnum={partEnums} />,
      {
          preventClosing: true,
          title: "Nové pravidlo protokolu",
          size: "xl",
      })
    }
  }

  const handleOpenModelRuleForm = (protocolRuleId: number) => {
    let rule: IRuleSettingDTO | undefined = protocolRulesSetting?.rulesSetings?.find(x => x.protocolRuleId === protocolRuleId);
    if(rule){
      rule.protocolRuleConditions = rule.protocolRuleConditions.map(conditions => ({ ...conditions, uid: uuidv4() }));
      openModal(
        <ProtocolRuleForm protocolRule={rule} enums={enums} areasEnum={areaEnums} groupsEnum={groupEnums} partsEnum={partEnums} />,
        {
            preventClosing: true,
            title: "Úprava pravidla protokolu",
            size: "xl",
        })
    }
  }

  const handleOpenModelRuleConditionDataForm = (protocolRuleId: number, protocolRuleConditionId: number) => {
    let rule: IRuleSettingDTO | undefined = protocolRulesSetting?.rulesSetings?.find(x => x.protocolRuleId === protocolRuleId);
    if(rule){
      let condition: IProtocolRuleConditionSettingDTO | undefined = rule.protocolRuleConditions.find(x => x.protocolRuleConditionId === protocolRuleConditionId);
      if(condition){
        openModal(
          <ProtocolRuleConditionDataForm protocolRuleConditionId={protocolRuleConditionId} protocolRule={rule} />,
          {
              preventClosing: true,
              title: "Úprava dat pravidla protokolu",
              size: "xl",
          })
      }
    }
  }

  const handleOpenModalCopyRulesForm = (protocolRuleId: number | undefined, carModelId: number | undefined, carBodyTypeId: number | undefined) => {
    const copyRules: IProtocolCopyRulesDTO = {
      protocolTypeId: protocolRuleId,
      originalCarModelId: carModelId,
      originalCarBodyTypeId: carBodyTypeId,
      newCarModelId: undefined,
      newCarBodyTypeId: undefined
    };
    if(copyRules){
    openModal(
      <ProtocolCopyRulesForm protocolCopyRules={copyRules} enums={enums} handleSuccessfullyCopyRules={handleSuccessfullyCopyRules} />,
      {
          preventClosing: true,
          title: "Kopírovat pravidla protokolu",
      })
    }
  }

  const handleSuccessfullyCopyRules = () => {
    toast.success("Pravidla protokolu byla úspěšně zkopírovaná");
  }

  const handleSubmitForm = (_: IProtocolRulesSetting) => {
  };

  return (
    <FinalForm
        onSubmit={handleSubmitForm}
        initialValues={protocolRulesSetting}
        render={({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div className="pageheader">
              <div className="pageheader__left">
                <h3>Konfigurace pravidel protokolu</h3>
              </div>
            <div className="pageheader__right">
              <Fragment>
                <OverlayTrigger overlay={
                  <Tooltip id={`tooltip-addbutton`}>Přidat pravidlo</Tooltip>
                  }
                  >
                  <Button
                    style={{ marginRight: "1rem" }}
                    variant="primary"
                    onClick={() => handleAddNewRule()}
                    className="footer__btn-add"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </OverlayTrigger>
              </Fragment>
                {protocolRulesSetting.rulesSetings && protocolRulesSetting.rulesSetings.length > 0 && (
                  <Button
                    size="sm"
                    variant="primary"
                    title="Kopírovat pravidla protokolu"
                    onClick={() => handleOpenModalCopyRulesForm(protocolType, carModelId, carBodyTypeId)}
                  >
                    Kopírovat
                  </Button>
                )}
            </div>
      </div>
      <div className="protocol-table" id="protocol-rule-settings">
        {protocolRulesSetting.rulesSetings && protocolRulesSetting.rulesSetings.length > 0 && (
          <Table>
            <thead>
              <tr>
                <th colSpan={2}>Název oblasti</th>
                <th colSpan={2}>Název skupiny</th>
                <th colSpan={2}>Název dílu</th>
                <th colSpan={2}>Limity</th>
                <th>Jednotky</th>
                <th>Funkce</th>
                <th>Aktivní</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {protocolRulesSetting.rulesSetings
                  .slice()
                  .sort((a, b) => (a.order - b.order))
                  .map((setting) => {
                    const index = protocolRulesSetting.rulesSetings.indexOf(setting);
                    return (
                        <Fragment key={`rule_${setting.protocolRuleId}`}>
                          <tr key={`protocolRuleRow_${setting.protocolRuleId}`} className={setting.protocolRuleType != 3 ? "disabled " : ""}>
                            <th colSpan={2}>
                              {setting.protocolAreaName}
                            </th>
                            <th colSpan={2}>
                              {setting.protocolPartGroupName}
                            </th>
                            <th colSpan={2}>
                              <div>{setting.protocolPartName}</div>
                              <div>{setting.additionalInfo}</div>
                            </th>
                            <td colSpan={2}>
                              {setting.rule}
                            </td>
                            <td>
                              {setting.unit}
                            </td>
                            <td colSpan={2} style={{ whiteSpace: "nowrap" }}>
                              {setting.protocolRuleFunctionName}
                            </td>
                            <td>
                              <Field
                                  name={`rulesSetings[${index}].isActive`}
                                  options={[
                                    {key: 1, value: "ano"},
                                    {key: 0, value: " ne "},
                                  ]}
                                  format={(v) => (v ? 1 : 0)}
                                  component={SwitchInput}
                                  disabled={true}
                              />
                            </td>
                            <td style={{paddingBottom: "0.75rem"}}>
                              <Button
                                  size="sm"
                                  style={{width: "3em"}}
                                  variant="primary"
                                  title="Editace pravidla"
                                  onClick={() => handleOpenModelRuleForm(setting.protocolRuleId)}
                              >
                                <FontAwesomeIcon icon={faPen}/>
                              </Button>
                            </td>
                          </tr>
                          {setting.connectionToCondition && setting.protocolRuleConditions && setting.protocolRuleConditions.length > 0 &&
                            setting.protocolRuleConditions.map((condition) => (

                              <tr key={`protocolRuleConditionRow_${condition.protocolRuleConditionId}`}>
                                <td colSpan={1}></td>
                                <td>Model:</td>
                                <td>{condition.modelFullName}</td>
                                <td>Pr čísla:</td>
                                <td colSpan={5}>{condition.prNumbersString}</td>
                                <td style={{ whiteSpace: "nowrap" }}>Počet rodin:</td>
                                <td colSpan={2}>{condition.countOfPrNumbers}</td>
                                <td>
                                  {condition.protocolRuleConditionId &&
                                    <Button
                                      size="sm"
                                      style={{width: "3em"}}
                                      variant="primary"
                                      title="Editace dat podmínky"
                                      onClick={() => handleOpenModelRuleConditionDataForm(setting.protocolRuleId, condition.protocolRuleConditionId!)}
                                      >
                                      <FontAwesomeIcon icon={faPen}/>
                                    </Button>
                                  }
                                </td>
                              </tr>

                          ))}
                        </Fragment>
                      )})}
                  </tbody>
                </Table>
            )}
      </div>
      <div className="pageheader">
              <div className="pageheader__left">
              </div>
              <div className="pageheader__right">
                <Fragment>
                  <OverlayTrigger overlay={
                    <Tooltip id={`tooltip-addbutton`}>Přidat pravidlo</Tooltip>
                    }
                    >
                    <Button
                      style={{ marginRight: "1rem" }}
                      variant="primary"
                      onClick={() => handleAddNewRule()}
                      className="footer__btn-add"
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </OverlayTrigger>
                </Fragment>
              </div>
            </div>
      </Form>
      )}
    />
  );
};

export default observer(ProtocolRuleTab);
