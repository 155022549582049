import React from "react";
import { Row, Col, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ICar, NewCar } from "../../../app/models/car";

interface IProps {
    values: ICar | NewCar;
}

const CarProtocolsTab: React.FC<IProps> = ({ values }) => {
  return (
    <div id="protocols-tab">
        <Row>
          <Col md={12}>
            <Table size="sm">
            <tbody>
            {values.mainProtocols && !!values.mainProtocols.length && (
                values.mainProtocols.map((protocol) =>
                <tr key={"Main_Protocol_" + protocol.carProtocolId}>
                    <td>{protocol.protocolTypeName}</td>
                    <td>
                        <Button
                        variant="outline-info"
                        key={"Protocol_" + protocol.carProtocolId}
                        size="sm"
                        as={Link}
                        to={protocol.url}
                        >
                        Zobrazit
                        </Button>
                    </td>
                </tr>
            ))}
            {values.partialProtocols && !!values.partialProtocols.length && (
                values.partialProtocols.map((protocol) =>
                <tr key={"Partial_Protocol_" + protocol.carProtocolId}>
                    <td>{protocol.protocolTypeName}</td>
                    <td>
                        <Button
                        variant="outline-info"
                        key={"Protocol_" + protocol.carProtocolId}
                        size="sm"
                        as={Link}
                        to={protocol.url}
                        >
                        Zobrazit
                        </Button>
                    </td>
                </tr>
            ))}
            </tbody>
            </Table>      
          </Col>
        </Row>

    </div>
  );
};

export default CarProtocolsTab;
