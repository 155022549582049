import React, { Fragment } from "react";
import { Row, Col, Table, Container } from "react-bootstrap";
import { Field, useForm, useFormState } from "react-final-form";
import { IProtocolArea, IEngineUnitAndChassisProtocol  } from "../../../../../app/models/protocol";
import CheckboxInput from "../../../../../app/common/form/CheckboxInput";
import TextAreaInput from "../../../../../app/common/form/TextAreaInput";
import AggregateFillingsGroup from "./AggregateFillingsGroup"
import ArrangementEngineCompartmentGroup from "./ArrangementEngineCompartmentGroup"

interface IProps {
  area: IProtocolArea;
  areaIndex: number;
  isEdit: boolean;
  isDisabledArea: boolean;
}

const AggregateAuditAarea: React.FC<IProps> = ({ area, areaIndex, isEdit, isDisabledArea }) => {

  const form = useForm<IEngineUnitAndChassisProtocol>();
  const formState = useFormState<IEngineUnitAndChassisProtocol>();

  return (
    <Fragment>
      <Container className={`mt-2 ${ isDisabledArea ? "disabled" : ""}`} key={"AggregateAuditAarea"}>

        <Table className={`protocol-table`}>
          <thead>
            <tr className={`${isDisabledArea ? "disabled" : ""}`}>
              <th colSpan={10} className={`headingrow`}>
                <h2>{area.name}</h2>
              </th>
              <th colSpan={2} className={`headingrow`}>
                <Field
                  name={`areas[${areaIndex}].notPerform`}
                  title="Neprovedeno"
                  options={[
                      { key: 1, value: "ano" },
                      { key: 0, value: " ne " },
                  ]}
                  format={(v) => (v ? 1 : 0)}
                  parse={(v) => (v ? true : false)}
                  type={"checkbox"}
                  initialValue={area.notPerform || false}
                  component={CheckboxInput}
                  inputOnChange={(e: any) => {
                      form.mutators.handleChangePerformAreaOrGroup(e);
                  }}
                  disabled={!isEdit}
                  labelWidth={8}
                  valueWidth={4}
                />
              </th>
            </tr>
          </thead>
        </Table>

      {area && area.protocolPartGroups && area.protocolPartGroups?.map((group, groupIndex) => {
        let isDisableGroup: boolean = false;
        if(formState.values.areas !== undefined && formState.values.areas[areaIndex] !== undefined &&
           formState.values.areas[areaIndex].protocolPartGroups !== undefined && formState.values.areas[areaIndex].protocolPartGroups[groupIndex] !== undefined)
        {
            isDisableGroup = formState.values.areas[areaIndex].protocolPartGroups[groupIndex].notPerform || false;
        }
        if(group.componentCode && group.componentCode === 'AggregateFillingsGroup')
        {
          return <AggregateFillingsGroup area={area} areaIndex={areaIndex} group={group} groupIndex={groupIndex} isEdit={isEdit} isDisabledArea={isDisabledArea} isDisabledGroup={isDisableGroup} />
        }
        else if (group.componentCode && group.componentCode === 'ArrangementEngineCompartmentGroup')
        {
          return <ArrangementEngineCompartmentGroup group={group} areaIndex={areaIndex} groupIndex={groupIndex} isEdit={isEdit} isDisabledArea={isDisabledArea} isDisabledGroup={isDisableGroup} />
        }
      })}

      <Row>
        <Col sm={12} style={{paddingTop: "1em"}} className={`${ area.notPerform ? "disabled" : ""}`}>
            <h3>{`${area.name} - Poznámka`}</h3>
            <Field<string>
                name={`areas[${areaIndex}].note`}
                component={TextAreaInput}
                initialValue={area.note}
                disabled={!isEdit || isDisabledArea}
                rows={5}
            />
        </Col>
      </Row>

    </Container>
  </Fragment>    
  );
};

export default AggregateAuditAarea;