import React, {Fragment, useContext, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Field, Form as FinalForm, useFormState} from "react-final-form";

import TextInput from "../../../app/common/form/TextInput";
import SelectInput from "../../../app/common/form/SelectInput";

import { RootStoreContext } from "../../../app/stores/rootStore";
import { AreaEnumDTO, GroupEnumDTO, PartEnumDTO, IProtocolRuleConditionDataExtSpecDTO } from "../../../app/models/protocol";
import { observer } from "mobx-react-lite";

import AlertMessage from "../../alert/AlertMessage";
import { useProtocolRuleDataExtensionSpecMutation } from "../../../app/api/client";
import {toast} from "react-toastify";

interface IProps {
    protocolRuledataExtensionSpec: IProtocolRuleConditionDataExtSpecDTO,
    areasEnum: AreaEnumDTO[] | undefined;
    groupsEnum: GroupEnumDTO[] | undefined;
    partsEnum: PartEnumDTO[] | undefined;
}

const ProtocolRuleDataExtensionSpecForm: React.FC<IProps> = ({protocolRuledataExtensionSpec, areasEnum, groupsEnum, partsEnum}) => {
  
    const rootStore = useContext(RootStoreContext);
    const { closeModal } = rootStore.modalStore;

    const [ errors, setErrors] = useState([]);
    const [ showSubmitError, setShowSubmitError] = useState(false);

    const [ editRuleDataExtSpec ] = useState(protocolRuledataExtensionSpec);
    const {mutateAsync: saveProtocolRuleDataExtensionSpecSetting} = useProtocolRuleDataExtensionSpecMutation();

    const [ selectedAreaId , setSelectedAreaId ] = useState<number>()
    const [ selectedGroupId , setSelectedGroupId ] = useState<number>()

    const handleFinalFormSubmit = (form: IProtocolRuleConditionDataExtSpecDTO) => {
        setErrors([]);
        setShowSubmitError(false);
        saveProtocolRuleDataExtensionSpecSetting(form)
        .then(() => {
            toast.success(`Konfigurace rozšiřujících dat pravidla protokolu byla uložena`);
            closeModal();
        })
        .catch((error: any) => {
        console.log(error);
        toast.error("Při ukládání konfigurace rozšiřujících dat pravidla protokolu nastala chyba!");
        setErrors(
            error.response?.data?.errors &&
            Object.values(error.response?.data?.errors)
        );
        setShowSubmitError(true);
        });
    };

  return (
    <div>
    <FinalForm
        onSubmit={handleFinalFormSubmit}
        initialValues={editRuleDataExtSpec}
        mutators={{
            selectAreaId: (args, state, utils) => {
            setSelectedAreaId(Number.parseInt(args[0].target.value))
            },
            selectGroupId: (args, state, utils) => {
            setSelectedGroupId(Number.parseInt(args[0].target.value))
            },
        }}
        render={({ handleSubmit, form }) => (
        <Form onSubmit={handleSubmit}>
            <AlertMessage
              type="danger"
              heading="Nastala chyba při ukládání"
              show={showSubmitError}
              setShow={setShowSubmitError}
              messageList={errors}
            />
            <Row>
              <Col sm={2} className="u-text-right">
                <Form.Label>Oblast</Form.Label>
              </Col>
              <Col sm={10}>
                <Field<number>
                  name={`protocolAreaId`}
                  initialValue={editRuleDataExtSpec.protocolAreaId}
                  size="sm"
                  inputOnChange={form.mutators.selectAreaId}
                  options={areasEnum?.filter(x => x.isActive)}
                  component={SelectInput}
                  isEmptyOption={true}
                  disabled={false}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={2} className="u-text-right"><Form.Label>Skupina</Form.Label></Col>
              <Col sm={10}>
                <Field<number>
                    name={`protocolPartGroupId`}
                    initialValue={editRuleDataExtSpec.protocolPartGroupId}
                    size="sm"
                    inputOnChange={form.mutators.selectGroupId}
                    options={groupsEnum?.slice()
                                        .filter(x => x.isActive && (selectedAreaId === undefined || (selectedAreaId && x.areaId === selectedAreaId)))
                                      }
                    component={SelectInput}
                    isEmptyOption={true}
                    disabled={false}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={2} className="u-text-right"><Form.Label>Díl</Form.Label></Col>
              <Col sm={10}>
                <Field<number>
                    name={`protocolPartId`}
                    initialValue={editRuleDataExtSpec.protocolPartId}
                    size="sm"
                    options={partsEnum?.slice()
                      .filter(x => x.isActive && ((selectedAreaId === undefined || (selectedAreaId && x.areaId === selectedAreaId)) &&
                                                  (selectedGroupId === undefined || (selectedGroupId && x.groupId === selectedGroupId))))
                                  }
                    component={SelectInput}
                    isEmptyOption={true}
                    disabled={false}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={2} className="u-text-right"><Form.Label>Název</Form.Label></Col>
              <Col sm={10}>
                <Field<string>
                  name={`name`}
                  initialValue={editRuleDataExtSpec.name}
                  component={TextInput}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={2} className="u-text-right"><Form.Label>Popis</Form.Label></Col>
              <Col sm={10}>
                <Field<string>
                  name={`description`}
                  initialValue={editRuleDataExtSpec.description}
                  component={TextInput}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={2} className="u-text-right"><Form.Label>Název atributu</Form.Label></Col>
              <Col sm={4}>
                <Field<string>
                  name={`attributeName`}
                  initialValue={editRuleDataExtSpec.attributeName}
                  component={TextInput}
                  />
              </Col>
              <Col sm={2} className="u-text-right"><Form.Label>Typ atributu</Form.Label></Col>
              <Col sm={4}>
                <Form.Group>
                  <Field
                    name={`attributeType`}
                    initialValue={editRuleDataExtSpec.attributeType}
                    component={TextInput}
                  />
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <div className="flex-row flex-center">
                <Button
                    type="submit"
                    className="btn-modal"
                >
                    Uložit
                </Button>
                <Button
                    type="button"
                    onClick={closeModal}
                    className="btn-modal"
                    variant="outline-secondary"
                >
                    Zrušit
                </Button>
            </div>
        </Form>
    )}
    />
    </div>
  );
};

export default observer(ProtocolRuleDataExtensionSpecForm);