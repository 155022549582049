import React, {Fragment, useContext, useState} from "react";
import {Button, Col, Form, Row, Alert } from "react-bootstrap";
import { Form as FinalForm, Field } from "react-final-form";
import TextInput from "../../../app/common/form/TextInput";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { IRuleSettingDTO, IProtocolRuleConditionSettingDTO } from "../../../app/models/protocol";
import { useProtocolRuleConditionDataExtSettingsQuery, useProtocolRuleConditionDataSettingsMutation } from "../../../app/api/client";
import {observer} from "mobx-react-lite";
import {toast} from "react-toastify";
import AlertMessage from "../../alert/AlertMessage";
import Loader from "../../loader/Loader";

interface IProps {
    protocolRuleConditionId: number,
    protocolRule: IRuleSettingDTO
}

const ProtocolRuleConditionDataForm: React.FC<IProps> = ({protocolRuleConditionId, protocolRule}) => {
  const rootStore = useContext(RootStoreContext);
  const { closeModal } = rootStore.modalStore;
  const [ errors, setErrors] = useState([]);
  const [ showSubmitError, setShowSubmitError] = useState(false);

  const {data: protocolRuleCondition, isFetching: isLoading} = useProtocolRuleConditionDataExtSettingsQuery(protocolRuleConditionId)
  const {mutateAsync: saveProtocolRuleConditionDataSetting} = useProtocolRuleConditionDataSettingsMutation();
  const handleFinalFormSubmit = (form: IProtocolRuleConditionSettingDTO) => {
    setErrors([]);
    setShowSubmitError(false);
   
    saveProtocolRuleConditionDataSetting(form)
    .then(() => {
        toast.success(`Konfigurace data podmínky pravidla protokolu byla uložena`);
        closeModal();
    })
    .catch((error: any) => {
//      console.log(error);
      toast.error("Při ukládání konfigurace dat podmínky pravidla protokolu nastala chyba!");
      setErrors(
        error.response?.data?.errors &&
          Object.values(error.response?.data?.errors)
      );
      setShowSubmitError(true);
    });
    
  };
  
  if (isLoading) {
    return <Loader/>;
}

  return (
    <Fragment>

        <FinalForm
            onSubmit={handleFinalFormSubmit}
            initialValues={protocolRuleCondition}
    
            render={({ handleSubmit, form }) => (
                <Form onSubmit={handleSubmit}>
                    <AlertMessage
                    type="danger"
                    heading="Nastala chyba při ukládání"
                    show={showSubmitError}
                    setShow={setShowSubmitError}
                    messageList={errors}
                    />


                <Row>
                    <Col sm={2} className="u-text-right">
                        <Form.Label>Oblast</Form.Label>
                    </Col>
                    <Col sm={10}>
                        <Field<string>
                            name="protocolAreaName"
                            initialValue={protocolRule.protocolAreaName}
                            component={TextInput}
                            disabled={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={2} className="u-text-right">
                        <Form.Label>Skupina</Form.Label>
                    </Col>
                    <Col sm={10}>
                        <Field<string>
                            name="protocolPartGroupName"
                            initialValue={protocolRule.protocolPartGroupName}
                            component={TextInput}
                            disabled={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={2} className="u-text-right">
                        <Form.Label>Díl</Form.Label>
                    </Col>
                    <Col sm={10}>
                        <Field<string>
                            name="protocolPartName"
                            initialValue={protocolRule.protocolPartName}
                            component={TextInput}
                            disabled={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={2} className="u-text-right">
                        <Form.Label>Model vozu</Form.Label>
                    </Col>
                    <Col sm={10}>
                        <Field<string>
                            name="modelFullName"
                            initialValue={protocolRuleCondition?.modelFullName}
                            component={TextInput}
                            disabled={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={2} className="u-text-right">
                        <Form.Label>PR čísla</Form.Label>
                    </Col>
                    <Col sm={10}>
                        <Field<string>
                            name="prNumbersString"
                            initialValue={protocolRuleCondition?.prNumbersString}
                            component={TextInput}
                            disabled={true}
                        />
                    </Col>
                </Row>
                {protocolRuleCondition?.dataExtensions.length === 0 &&
                    <Row>
                        <Col>
                            <Alert variant="info">
                                Pro danou podmínku nebyla specifikovaná rozšiřující data.
                            </Alert>
                        </Col>
                    </Row>
                }
                {protocolRuleCondition?.dataExtensions && protocolRuleCondition?.dataExtensions.length > 0 && (

                    <Fragment>
                        {protocolRuleCondition.dataExtensions.map((data, index) => (
                            <Row>
                                <Col xs={1}></Col>
                                <Col xs={4}>{data.dataExtensionName}</Col>
                                <Col>
                                    <Field<string>
                                        name={`dataExtensions[${index}].dataExtensionValue`}
                                        initialValue={data.dataExtensionValue}
                                        component={TextInput}
                                    />
                                </Col>
                            </Row>
                        ))}
                    </Fragment>

                )}

                    
                    <div className="flex-row flex-center">
                        <Button
                            type="submit"
                            className="btn-modal"
                        >
                            Uložit
                        </Button>

                        <Button
                            type="button"
                            onClick={closeModal}
                            className="btn-modal"
                            variant="outline-secondary"
                        >
                            Zrušit
                        </Button>
                    </div>
                </Form>
            )}
        />
    </Fragment>
  );
};

export default observer(ProtocolRuleConditionDataForm);