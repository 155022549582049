import "./AttachmentsList.scss";
import React from "react";
import { IAttachment } from "../../app/models/attachment";
import { Button, Col, ListGroup, Row } from "react-bootstrap";
import { defaultStyles, FileIcon } from "react-file-icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import agent from "../../app/api/agent";

interface IProps {
    attachments: IAttachment[] | undefined;
    colSize: number;
    deleteAttachments(attachmentId: number) : void;
}

  const AttachmentsList: React.FC<IProps> = ({ attachments, colSize, deleteAttachments }) => {

    const existsAttachments = attachments && attachments.length;

    const downloadAttachment = async (id: number, fileName: string) => {

        await agent.Attachments.getAttachementData(id)
            .then((response) => {
                const href = URL.createObjectURL(response.data);
                //console.log(response.headers);
          
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', fileName); //or any other extension
                link.setAttribute("target", "_blank")
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            }) 
            .catch((error) => {
                //console.log(error);
            });
    };

    const deleteAttachment = async (id: number) => {

        await agent.Attachments.deleteAttachement(id)
            .then(() => {
                deleteAttachments(id);
                toast.success(`Soubor byl smazán`);
            }) 
            .catch((error) => {
                //console.log(error);
            });
    };
  
    if(!existsAttachments) return <></>

    return (

        <Col md={colSize}>
            <ListGroup as="ul">
            {attachments.map((attachment) => {
                    return (
                    <ListGroup.Item key={attachment.attachmentId} as="li" className="m-0 p-0 border-0">
                        <Row className="d-flex align-items-center justify-content-start align-items-start">
                            <Col className="m-0 p-0 d-flex justify-content-center" sm={1}>
                            <div className="fileIcon">
                                <FileIcon
                                    extension={attachment.extension}
                                    {...defaultStyles[attachment.extension]}
                                />
                            </div>
                            </Col>
                            <Col className="m-0 p-0" sm={6}>
                                {attachment.fileName}
                            </Col>
                            <Col className="m-0 p-0 d-flex justify-content-end" sm={3}>
                                {attachment.size}
                            </Col>
                            <Col className="m-0 p-0 d-flex justify-content-center" sm={1}>
                                <Button
                                    variant="link"
                                    size="sm"
                                    onClick={() => downloadAttachment(attachment.attachmentId, attachment.fileName)}
                                    title="Odstranit přílohu"
                                >
                                    <FontAwesomeIcon icon={faDownload} />
                                </Button>
                            </Col>
                            <Col className="m-0 p-0 d-flex justify-content-center" sm={1}>
                        
                                <Button
                                    variant="link"
                                    size="sm"
                                    onClick={() => deleteAttachment(attachment.attachmentId)}
                                    title="Odstranit přílohu"
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                        
            
                            </Col>
                        </Row>
                    </ListGroup.Item>
                    )
                })
            }
            </ListGroup>
        </Col>
    );
  };
  
  export default AttachmentsList;
  