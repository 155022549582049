import React, { Fragment, useState, useContext } from "react";
import { Field, useFormState } from "react-final-form";
import TextInput from "../../../app/common/form/TextInput";
import { Card, Row, Col, Table, Collapse, OverlayTrigger, Tooltip, Button, Form } from "react-bootstrap";
import { IRuleSettingDTO } from "../../../app/models/protocol";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faPlus } from "@fortawesome/free-solid-svg-icons";
import {RootStoreContext} from "../../../app/stores/rootStore";
import SwitchInput from "../../../app/common/form/SwitchInput";
import ProtocolRuleConditionPrRalations from "./ProtocolRuleConditionPrRalations";

interface IProps {
    protocolRule: IRuleSettingDTO
}

const ProtocolRuleConditionForm: React.FC<IProps> = ({protocolRule}) => {
    const rootStore = useContext(RootStoreContext);
    const enums = rootStore.enumStore.enums;
    const { getProtocolRuleConditionWithPrRelations, getProtocolRuleConditionPrRelation } = rootStore.protocolStore;

    const [newConditionCarModelId, setNewConditionCarModelId] = useState<number | undefined>();
    const [newConditionCarBodyTypeId, setNewConditionCarBodyTypelId] = useState<number | undefined>();
    const [newConditionPrRelationString, setNewConditionPrRelationString] = useState<string | undefined>();

    const formState = useFormState<IRuleSettingDTO>();
    const showCondition = formState.values.connectionToCondition || false;
    const [openConditionUid, setOpenConditionUid] = useState<string>('');
    const [showNewConditionForm, setShowNewConditionForm] = useState<boolean>(false);
    const [renderCount, setRenderCount] = useState<number>(0);

    
    const handleClickOpenConditionRelations = (conditionUid: string) => {
      if(openConditionUid === conditionUid)
        setOpenConditionUid('');     
      else
        setOpenConditionUid(conditionUid);   
    }

    const handleAddConditionForm = () => {
      setShowNewConditionForm(true);
    }

    const getPrconditionRelations = () => {
      if(newConditionPrRelationString){
        getProtocolRuleConditionWithPrRelations(newConditionPrRelationString, newConditionCarModelId, newConditionCarBodyTypeId).then((newPrCondition) => {
          protocolRule.protocolRuleConditions.push(newPrCondition);
          setShowNewConditionForm(false);
        })
      }
    }

    const addPrRaletionToCondition = (conditionIndex: number) => {
        let condition  = formState.values.protocolRuleConditions[conditionIndex];
        let addedPrNumber = condition.addedPrNumber;
        if(addedPrNumber !== undefined){
            getProtocolRuleConditionPrRelation(addedPrNumber).then((newPrRealtion) => {
                condition.prRelations.push(newPrRealtion);
                condition.addedPrNumber = undefined;
                setRenderCount(renderCount + 1)
          })
        }
    }

    if(!showCondition) return <></>

    return (
        <Card>
            <Card.Header className="pageheader" style={{marginTop: 0}}>
                <div className="pageheader__left">
                    <h3>PR podmínky</h3>
                </div>
                <div className="pageheader__right">
                  {!showNewConditionForm && 
                    <Fragment>
                      <OverlayTrigger overlay={ <Tooltip id={`tooltip-addbutton`}>Přidat podmínku</Tooltip> }>
                          <Button
                            style={{ marginRight: "1rem" }}
                            variant="primary"
                            onClick={() => handleAddConditionForm()}
                            className="footer__btn-add"
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </Button>
                        </OverlayTrigger>
                    </Fragment>  
                  }
                </div>
            </Card.Header>

            {showNewConditionForm &&
            
              <Card.Body key={"newConditionForm"}>
                <Row style={{ marginTop: 5, marginBottom: 5 }}>
                  <Col sm={2} className="u-text-right">
                    <Form.Label>Model vozu</Form.Label>
                  </Col>
                  <Col sm={4}>
                    {enums && (
                      <Form.Control
                        as="select"
                        name={"newConditionCarModel"}
                        onChange={(e: any) => {
                          setNewConditionCarModelId(Number.parseInt(e.target.value))
                        }
                        }
                        value={newConditionCarModelId?.toString()}
                        >
                        <option></option>
                        {enums?.carModels
                          .slice()
                          .sort((a, b) => (a.name < b.name ? -1 : 1))
                          .map((option) => (
                            <option key={option.id} value={option.id}>
                              {(option.modelVdsCode !== null ? option.modelVdsCode + ' - ' : "" ) + option.name}
                            </option>
                          ))}
                      </Form.Control>
                    )}
                  </Col>
                  <Col sm={2} className="u-text-right">
                    <Form.Label>Karoserie vozu</Form.Label>
                  </Col>
                  <Col sm={4}>
                    {enums && (
                      <Form.Control
                        as="select"
                        name={"newConditionCarBodyType"}
                        onChange={(e: any) => {
                          setNewConditionCarBodyTypelId(Number.parseInt(e.target.value))
                        }}
                        value={newConditionCarBodyTypeId?.toString()}
                        >
                        <option></option>
                        {enums?.carBodyTypes
                          .filter(x => x.carModelId == newConditionCarModelId)
                          .slice()
                          .sort((a, b) => (a.name < b.name ? -1 : 1))
                          .map((option) => (
                            <option key={option.id} value={option.id}>
                              {(option.modelVdsCode !== null ? option.modelVdsCode + ' - ' : "" ) + option.name}
                            </option>
                          ))}
                      </Form.Control>
                    )}
                  </Col>
                </Row>
                <Row style={{ marginTop: 5, marginBottom: 5 }}>
                  <Col sm={2} className="u-text-right"><Form.Label>PR čísla</Form.Label></Col>
                  <Col sm={4}>
                    <Field
                      name={`newConditionPrRelation`}
                      initialValue={newConditionPrRelationString}
                      component={TextInput}
                      onChange={(e: any) => { setNewConditionPrRelationString(e.target.value); }}
                      size={"sm"}
                    />
                  </Col>
                  <Col sm={6} className="flex-row flex-center">
                      <Button
                          type="button"
                          onClick={getPrconditionRelations}
                          className="btn-modal"
                      >
                          Načíst PR podmínku
                      </Button>
                  </Col>
                </Row>
              </Card.Body>

            }

            <Card.Body key={"conditionRows"}>
                <Row style={{borderBottom: "1px solid #4ba82e"}}>
                    <Col lg={3}><h4>Model vozu</h4></Col>
                    <Col lg={3}><h4>PR čísla</h4></Col>
                    <Col lg={3}><h4>Počet rodin</h4></Col>
                    <Col lg={1}><h4> </h4></Col>
                    <Col lg={2}><h4> </h4></Col>
                </Row>
                {protocolRule.protocolRuleConditions.map((condition, conditionIndex) => (
                    <Fragment>
                        <Row style={{borderBottom: "1px solid rgb(200, 200, 200)"}}>
                            <Col lg={3}>{condition.modelFullName}</Col>
                            <Col lg={3}>{condition.prNumbersString}</Col>
                            <Col lg={3}>{condition.countOfPrNumbers}</Col>
                            <Col lg={1}>
                                <button
                                onClick={() => handleClickOpenConditionRelations(condition.uid)}
                                type="button"
                                aria-controls="car-parametres"
                                aria-expanded={openConditionUid === condition.uid}
                                className={`btn-link btn-link--primary animate-${openConditionUid === condition.uid ? "up" : "down"}`}
                                >
                                    {openConditionUid !== condition.uid && <FontAwesomeIcon icon={faChevronDown} />}
                                    {openConditionUid === condition.uid  && <FontAwesomeIcon icon={faChevronUp} />}
                                </button>
                            </Col>
                            <Col lg={2}>
                              <Field
                                name={`protocolRuleConditions[${conditionIndex}].isActive`}
                                options={[
                                  { key: 1, value: "ano" },
                                  { key: 0, value: " ne " },
                                ]}
                                format={(v) => (v ? 1 : 0)}
                                parse={(v) => (v ? true : false)}
                                component={SwitchInput}
                              />
                            </Col>
                        </Row>
                        <ProtocolRuleConditionPrRalations condition={condition} conditionIndex={conditionIndex} openConditionUid={openConditionUid} handleAddPrRaletionToCondition={addPrRaletionToCondition}  />        
                    </Fragment>
                ))}
            </Card.Body>
        </Card>
    );
  };
  
  export default observer(ProtocolRuleConditionForm);